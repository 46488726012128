<script setup lang="ts">
interface Props {
  fullScreen?: boolean;
  text?: string;
  size?: "sm" | "md" | "lg";
}

withDefaults(defineProps<Props>(), {
  fullScreen: false,
  size: "md",
});

const sizeClasses = {
  sm: "w-4 h-4",
  md: "w-8 h-8",
  lg: "w-12 h-12",
};

</script>

<template>
  <div
    :class="[
      'flex items-center justify-center',
      fullScreen && 'fixed inset-0 z-50 bg-black/50',
    ]"
  >
    <div class="flex flex-col items-center gap-2">
      <div
          :class="['animate-spin rounded-full border-4 border-gray-300 border-t-screenfeed-blue', sizeClasses[size]]"
        ></div>    
      <span v-if="text">{{ text }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
// If we have a light/dark toggle, this should flip.
const fill = "currentColor";
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 200 42"
    fill="none"
    preserveAspectRatio="xMidYMid meet"
  >
    <g clip-path="url(#clip0_2719_9163)">
      <path
        d="M27.7369 30.4112L0 26.5895V6.5718L27.7369 0.0227051V30.4112Z"
        fill="url(#paint0_linear_2719_9163)"
      />
      <path
        d="M5.24432 41.9775L32.6108 34.0732V15.0825L5.24432 11.084V41.9775Z"
        fill="url(#paint1_linear_2719_9163)"
      />
      <path
        d="M5.24432 11.084V27.2968L27.7369 30.4114V14.3922L5.24432 11.084Z"
        fill="#330066"
      />
      <g :fill>
        <path d="M7.16339 19.8134V13.0286L11.7848 13.7778" />
        <path
          d="M49.455 19.1397C46.3825 18.3569 45.6249 17.9781 45.6249 16.808V16.7575C45.6249 15.9157 46.4077 15.2086 47.9061 15.2086C49.1177 15.2507 50.2991 15.5981 51.3406 16.2188L53.9838 13.584C52.2132 12.363 50.1072 11.7218 47.9566 11.7489C44.3285 11.7489 41.7274 13.887 41.7274 17.111V17.1615C41.7274 20.6886 44.0507 21.6819 47.6199 22.5911C50.5998 23.3571 51.2059 23.8706 51.2059 24.8555V24.906C51.2059 25.9498 50.2379 26.5896 48.6385 26.5896C46.9847 26.5714 45.3824 26.0127 44.076 24.9986L41.4917 27.5913C43.5033 29.1851 45.9963 30.0487 48.5627 30.0409C52.4012 30.0409 55.0865 28.0627 55.0865 24.5356V24.4851C55.0865 21.3873 53.0578 20.091 49.455 19.1482"
        />
        <path
          d="M64.6659 26.4804C61.6186 26.4804 59.5057 23.955 59.5057 20.8993V20.8488C59.5057 17.7931 61.6691 15.3099 64.6659 15.3099C66.1704 15.3316 67.6156 15.9001 68.7317 16.9093L71.4507 14.1987C70.5556 13.3467 69.4979 12.684 68.3408 12.2501C67.1837 11.8162 65.951 11.62 64.7164 11.6734C63.4973 11.6436 62.2847 11.8609 61.1517 12.312C60.0188 12.7631 58.9888 13.4387 58.1237 14.2983C57.2586 15.1578 56.5764 16.1835 56.1181 17.3136C55.6598 18.4437 55.4348 19.6548 55.4567 20.8741V20.9246C55.434 22.1281 55.6538 23.3239 56.1032 24.4406C56.5525 25.5574 57.2222 26.5722 58.0722 27.4246C58.9222 28.2769 59.9351 28.9494 61.0506 29.4018C62.1661 29.8543 63.3613 30.0774 64.5649 30.058C65.8082 30.1116 67.049 29.9057 68.2084 29.4536C69.3678 29.0014 70.4204 28.3128 71.2992 27.4316L68.7738 24.9062C67.6654 25.9355 66.2036 26.4991 64.6911 26.4804"
        />
        <path
          d="M89.8187 29.7797V12.0012H103.228V15.4862H93.7078V19.0891H102.631V22.5656H93.7078V26.3032H103.355V29.7797H89.8187Z"
        />
        <path
          d="M105.088 29.7797V12.0012H118.498V15.4862H108.978V19.0891H117.9V22.5656H108.978V26.3032H118.624V29.7797H105.088Z"
        />
        <path
          d="M132.825 29.7797L124.222 18.483V29.7797H120.359V12.0012H123.961L132.295 22.9444V12.0012H136.151V29.7797H132.825Z"
        />
        <path
          d="M142.077 15.562V19.3416H150.562V22.8939H142.077V29.7797H138.171V12.0012H151.707V15.562H142.077Z"
        />
        <path
          d="M153.079 29.7797V12.0012H166.489V15.4862H156.968V19.0891H165.344V22.5656H156.968V26.3032H166.615V29.7797H153.079Z"
        />
        <path
          d="M168.349 29.7797V12.0012H181.759V15.4862H172.238V19.0891H180.614V22.5656H172.238V26.3032H181.885V29.7797H168.349Z"
        />
        <path
          d="M190.555 29.7801H183.619V12.01H190.555C196.136 12.01 200 15.8401 200 20.8488V20.8993C200 25.8995 196.136 29.7801 190.555 29.7801ZM195.909 20.8993C195.946 20.1859 195.834 19.4727 195.578 18.8057C195.322 18.1388 194.929 17.5329 194.425 17.0274C193.92 16.5219 193.315 16.1281 192.648 15.8714C191.982 15.6146 191.268 15.5008 190.555 15.5371H187.525V26.2446H190.555C191.265 26.2883 191.977 26.1814 192.642 25.931C193.308 25.6806 193.914 25.2924 194.419 24.7917C194.925 24.291 195.319 23.6892 195.575 23.0257C195.832 22.3621 195.946 21.6519 195.909 20.9414V20.8993Z"
        />
        <path
          d="M83.9934 29.7801L80.1885 24.0981H77.116V29.7801H73.2017V12.01H81.325C85.5339 12.01 88.0593 14.2155 88.0593 17.9025V17.953C88.116 19.162 87.7733 20.356 87.0842 21.3509C86.395 22.3459 85.3977 23.0864 84.246 23.4583L88.5896 29.8054L83.9934 29.7801ZM84.0692 18.0793C84.0692 16.3957 82.8991 15.5539 80.9967 15.5539H77.116V20.6551H81.0724C82.9749 20.6551 84.0692 19.645 84.0692 18.1298V18.0793Z"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2719_9163"
        x1="-4.84869"
        y1="29.4347"
        x2="29.0164"
        y2="9.6359"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#4996E6" />
        <stop offset="0.2" stop-color="#40A0E8" />
        <stop offset="0.53" stop-color="#29BBEE" />
        <stop offset="0.96" stop-color="#04E7F7" />
        <stop offset="1" stop-color="#00ECF8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2719_9163"
        x1="22.644"
        y1="16.8755"
        x2="3.72911"
        y2="45.9087"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.12" stop-color="#9C2ADC" />
        <stop offset="0.25" stop-color="#B425BF" />
        <stop offset="0.51" stop-color="#F01A74" />
        <stop offset="0.58" stop-color="#FF1761" />
        <stop offset="0.88" stop-color="#FF8B21" />
      </linearGradient>
      <clipPath id="clip0_2719_9163">
        <rect
          width="200"
          height="41.9546"
          fill="white"
          transform="translate(0 0.0227051)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

import { PackageInfoArrayGenericResponse, PackageInfo } from "@be/api";
import { api } from "@/backend";
import { user } from "@/user";
import { useAsyncState } from "@vueuse/core";
import { computed, ref } from "vue";
import { isNonEmptyString } from "@/utils";
import { auth } from "@/authService";

// This will keep menu in sync with the user's account
const { state, isReady, isLoading } = useAsyncState(
  async () => {
    const token = await auth.getAccessToken();
    const authorization = `Bearer ${token}`;
    const response = await api.getContentMenu(authorization, {
      accountId: user.value?.accountId,
    });
    return response.data;
  },
  { data: [] } as PackageInfoArrayGenericResponse,
);

export { isReady, isLoading };

// This keeps track of user filter selections
const filter = ref<string[]>([]);

export const packages = computed(() => state.value.data ?? []);

const totalFeeds = computed(
  () => state.value.data?.reduce((acc, p) => acc + p.feedCount, 0) ?? 0,
);

const uniqueCategories = computed(() => {
  const categories = new Set<string>();
  packages.value.forEach((p) => p.categories.forEach((c) => categories.add(c)));
  return Array.from(categories);
});

export const categories = computed(() => {
  const categories = uniqueCategories.value.map((r) => {
    return { value: r, text: r, selected: filter.value.includes(r) };
  });

  if (totalFeeds.value > 0) {
    categories.unshift({
      value: "inuse",
      text: "Apps You're Using",
      selected: filter.value.includes("inuse"),
    });
  }

  return categories;
});

export const toggleFilter = (category: string) => {
  if (filter.value.includes(category)) {
    filter.value = filter.value.filter((c) => c !== category);
  } else {
    filter.value.push(category);
  }
};

export const filteredItems = computed<PackageInfo[]>(() => {
  // If no filter is selected, return all packages sorted by order
  if (filter.value.length === 0) {
    return packages.value
      .filter((p) => isNonEmptyString(p.slug))
      .sort((a, b) => a.order - b.order);
  }

  return packages.value
    .filter(
      (p) =>
        isNonEmptyString(p.slug) &&
        (filter.value.some((v) => p.categories.includes(v)) ||
          (filter.value.includes("inuse") && p.feedCount > 0)),
    )
    .sort((a, b) => a.name.localeCompare(b.name));
});

export const getPackage = (packageId: number | string) => {
  let id = typeof packageId === "string" ? parseInt(packageId, 10) : packageId;
  return packages.value.find((p) => p.packageId === id);
};

<script setup lang="ts">
import Header from "./components/Header.vue";
import Toaster from "@/components/ui/toast/Toaster.vue";
import Spinner from "@/components/ui/spinner/Spinner.vue";
import { useSpinner } from "@/components/ui/spinner/useSpinner";
import { listenForFeedPublishUpdates } from "./externalFeedPublishService";

const { isVisible, text } = useSpinner();
listenForFeedPublishUpdates();
</script>

<template>
  <div class="relative z-10 mx-auto w-[944px] pt-14 text-foreground">
    <Header class="mb-7" />
    <RouterView class="pb-24 pt-6" />
    <Toaster />
  </div>

  <Spinner v-if="isVisible" fullScreen :text="text" />

  <svg
    class="pointer-events-none fixed left-0 top-0 -z-0 -translate-x-1/2 transform"
    width="800"
    height="800"
    viewBox="0 0 800 800"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_f_456_27199)">
      <circle cx="400" cy="400" r="200" fill="#021622" />
    </g>
    <defs>
      <filter
        id="filter0_f_456_27199"
        x="0"
        y="0"
        width="800"
        height="800"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="100"
          result="effect1_foregroundBlur_456_27199"
        />
      </filter>
    </defs>
  </svg>

  <svg
    class="pointer-events-none fixed right-0 top-0 -z-0 translate-x-1/2 transform"
    width="800"
    height="800"
    viewBox="0 0 800 800"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_f_456_27200)">
      <circle cx="400" cy="400" r="200" fill="#1B051F" />
    </g>
    <defs>
      <filter
        id="filter0_f_456_27200"
        x="0"
        y="0"
        width="800"
        height="800"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="100"
          result="effect1_foregroundBlur_456_27200"
        />
      </filter>
    </defs>
  </svg>
</template>

<style scoped></style>

export function wait(timeout: number) {
  return new Promise<void>((resolve) => setTimeout(resolve, timeout));
}

export function isNonEmptyString(value: unknown) {
  return typeof value === "string" && value.length > 0;
}

export function isNonEmptyValue(value: unknown) {
  return isNonEmptyString(value?.toString());
}

export function makeId(length = 6) {
  let result = "";
  const characters = "abcdefghijklmnopqrstuvwxyz";
  const len = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * len));
  }
  return result;
}

/**
 * Returns an array of unique values that are present in all given arrays
 *
 * Empty arrays are ignored
 *
 * @param {...Array} arrays - Arrays to find common elements
 * @returns {Array} Array of common elements
 */
export function intersection(...arrays: any[]) {
  // Filter out empty arrays
  arrays = arrays.filter((arr) => arr.length > 0);

  // If no arrays are provided, return an empty array
  if (arrays.length === 0) return [];

  // If only one array is provided, return unique values from that array
  if (arrays.length === 1) return [...new Set(arrays[0])];

  // Convert the first array to a set for efficient lookup
  const firstSet = new Set(arrays[0]);

  // Filter the first set to keep only elements that exist in all other arrays
  return [
    ...new Set(
      [...firstSet].filter((item) =>
        arrays.slice(1).every((arr) => arr.includes(item)),
      ),
    ),
  ];
}

//Takes a comma separated value string and converts it to an array of strings
export function arrayFromCSV(value: string): Array<string> {
  return (value || "")
    .split(",")
    .map((v) => v.trim())
    .filter((v) => v.length > 0);
}

//Takes an array of strings and converts it to a comma separated value string
export function arrayToCSV(arr: Array<string | number | boolean>): string {
  return arr
    .filter(
      (item) =>
        item !== undefined &&
        item !== null &&
        item.toString().trim().length > 0,
    )
    .map((item) => item.toString().trim())
    .join(",");
}

export function removeNullOrEmptyProps(obj: any) {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (
      newObj[key] === null ||
      (typeof newObj[key] === "string" && newObj[key].trim() === "")
    ) {
      delete newObj[key];
    }
  });
  return newObj;
}
/**
  Creates a duplicate-free version of an array, keeping only the first occurrence of each element.*/
export function removeDuplicates<T>(
  array: T[],
  iteratee: (item: T) => any,
): T[] {
  const seen = new Map<any, T>();

  for (const item of array) {
    const key = iteratee(item);
    if (!seen.has(key)) {
      seen.set(key, item);
    }
  }

  return Array.from(seen.values());
}

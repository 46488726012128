import { auth } from "@/authService";
import { appSettings } from "@/appSettings";
import { Configuration, DefaultApiFactory } from "@be/index";
import axios, { AxiosRequestConfig } from "axios";

const axiosInstance = axios.create({
  baseURL: appSettings.backEnd.baseUrl,
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    let originalRequest = error.config;

    if (error instanceof Error && error.name !== "AbortError") {
      return Promise.reject(error);
    }

    if (error.response.status === 401 && originalRequest._rety)
      return auth.logout();

    if (error.response.status === 401) {
      originalRequest._rety = true;

      if (auth.isImpersonating()) auth.logout();

      return (await auth.getOrRenewAccessToken())
        ? axiosInstance.request(originalRequest)
        : auth.logout();
    }

    return Promise.reject(error);
  },
);

const apiConfig = new Configuration({
  accessToken: async () => {
    console.log("Getting access token");
    const result = await auth.getAccessToken();
    return result ?? "";
    // console.log("tokren", result);
    // return result !== null ? result : "";
  },
});

export const api = DefaultApiFactory(apiConfig, undefined, axiosInstance);

// Special Function for Image Uploads ==========================

export type ImageUpload = {
  imageUrl: string | undefined;
  height: number;
  width: number;
};

export async function uploadFeedLogoImage(
  data: FormData,
  config: AxiosRequestConfig<FormData>,
): Promise<ImageUpload> {
  const token = await auth.getAccessToken();

  if (token === null) {
    return Promise.reject("No access token");
  }

  if (config.headers === undefined) {
    config.headers = {};
  }

  config.headers.Authorization = `Bearer ${token}`;

  // Use old UploadLogo path until v2 is fixed
  const path = `/acct/UploadLogo`; // /acct/v2/UploadLogo`;

  return axiosInstance
    .post(path, data, config)
    .then((response) => response.data.data);
}

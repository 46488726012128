import { ref, computed } from "vue";
import { api } from "@/backend";
import { GetAccountResponse as Account } from "@be/api";

const acct = ref<Account | undefined>(undefined);
const childAcct = ref<Account | undefined>(undefined);

async function getAccount(
  accountId: number | undefined,
): Promise<Account | undefined> {
  if (accountId === undefined) {
    return Promise.reject();
  }

  var currAcct = contextAccount.value;
  if (currAcct && currAcct.accountId === accountId)
    return Promise.resolve(undefined);

  try {
    const response = await api.getAccount({ accountId });
    return Promise.resolve(response.data.data);
  } catch (error) {
    console.error("Failed to get account", error);
    return Promise.reject(error);
  }
}

export async function populateAccount(accountId: number | undefined) {
  if (accountId === undefined) {
    clearAccount();
    return Promise.reject();
  }

  var currAcct = contextAccount.value;
  if (currAcct && currAcct.accountId === accountId) return Promise.resolve();

  try {
    acct.value = await getAccount(accountId);
    return Promise.resolve();
  } catch (error) {
    console.error("Failed to get account", error);
    clearAccount();
    return Promise.reject(error);
  }
}

export function clearAccount() {
  acct.value = undefined;
  clearChildAccount();
}

export function clearChildAccount() {
  childAcct.value = undefined;
}

export async function populateChildAccount(accountId: number | undefined) {
  if (accountId === undefined) {
    clearChildAccount();
    return Promise.reject();
  }

  var currChildAcct = childAcct.value;

  if (currChildAcct && currChildAcct.accountId === accountId)
    return Promise.resolve();

  try {
    childAcct.value = await getAccount(accountId);
    return Promise.resolve();
  } catch (error) {
    console.error("Failed to get child account", error);
    clearChildAccount();
    return Promise.reject(error);
  }
}

export const shouldShowConnect = computed(() => {
  if (!acct.value || !acct.value.accountAppAccess) return true;

  var connectAccess = acct.value.accountAppAccess.find(
    (access) => access.appId === 22,
  );
  return connectAccess && connectAccess.hasAccess === true;
});

export const contextAccount = computed(() => childAcct.value ?? acct.value);

import { computed, readonly, ref } from "vue";

type AccountType =
  | "internal"
  | "customer"
  | "reseller"
  | "external"
  | "distributor"
  | "billboard"
  | "referrer"
  | "demo";

type ExternalAccount = {
  id: number;  
  providerSlug: string;
};

type UserProfile = {
  role: string[];
  accountId?: number;
  email: string;
  email_verified: boolean;
  unique_name: string;
  accountType: AccountType;
  externalAccount: ExternalAccount;
};

const profile = ref<UserProfile | null>(null);

function tryParseNumber(value: unknown) {
  if (typeof value === "string") {
    const number = parseInt(value, 10);
    if (!isNaN(number)) {
      return number;
    }
  }

  return undefined;
}

export function updateUserProfile(payload: unknown) {
  if (payload === undefined) {
    profile.value = null;
    return;
  }

  const oidcProfile = payload as Record<string, any>;  
  const ea = oidcProfile["https://schema.screenfeed.com/claims/externalAccount"];
  profile.value = {
    role: oidcProfile.role,
    accountId: tryParseNumber(
      oidcProfile["https://schema.screenfeed.com/claims/accountId"],
    ),
    email: oidcProfile.email,
    email_verified: oidcProfile.email_verified === "true",
    unique_name: oidcProfile.unique_name,
    accountType:
      oidcProfile["https://schema.screenfeed.com/claims/accountType"],
    externalAccount: ea ? JSON.parse(ea) : null,
  };
}

window.addEventListener("message", (event) => {
  if (event.data.accountType) {
    profile.value!.accountType = event.data.accountType;
  }
});

export const isExternalUser = computed(
  () => user.value?.externalAccount?.id !== undefined,
);

export const user = readonly(profile);

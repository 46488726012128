import {
  CreateFeedResponse,
  ExternalFeed,
  ExternalFeedStatus,
  FeedInfo,
} from "@be/api";
import { api } from "@/backend";
import { user } from "@/user";
import { isDefined } from "@vueuse/core";
import { ref, watch } from "vue";

export const isLoadingFeeds = ref(false);
export const feeds = ref<FeedInfo[]>([]);

watch(
  () => user.value?.accountId,
  async (accountId) => {
    if (!isDefined(accountId)) {
      return;
    }

    isLoadingFeeds.value = true;
    const response = await api.getFeeds({ accountId });
    isLoadingFeeds.value = false;
    const result = response.data.data;
    if (isDefined(result)) {
      feeds.value = result;
    }
  },
  { immediate: true },
);

export async function renameFeed(feedId: number, newName: string) {
  const feed = feeds.value.find((f) => f.feedId === feedId);

  if (feed) {
    const currentName = feed.name;
    console.log({ current: currentName, new: newName });
    if (currentName === newName) {
      console.log("Provided name is the same as the current one");
      return Promise.resolve();
    }

    feed.name = newName;

    return api
      .renameFeed({
        feedId,
        Name: newName,
      })
      .catch((error) => {
        console.warn(
          `Failed to rename feed "${currentName}" to "${newName}"`,
          error,
        );
        feed.name = currentName;
        return Promise.reject(error);
      });
  }

  console.log("Feed not found");
  return Promise.resolve();
}

export function removeFeed(feedId: number) {
  if (!isDefined(feeds)) {
    return Promise.resolve();
  }

  const feedIndex = feeds.value.findIndex((f) => f.feedId === feedId);
  if (feedIndex !== -1) {
    const feed = feeds.value[feedIndex]!;
    feeds.value.splice(feedIndex, 1);

    return api.removeFeed({ feedId }).catch((error) => {
      console.warn(`Failed to delete feed "${feed.name}"`, error);
      feeds.value.splice(feedIndex, 0, feed);
      return Promise.reject(error);
    });
  }

  console.log("Feed not found");
  return Promise.resolve();
}

export async function cloneFeed(feedId: number) {
  if (!isDefined(feeds)) {
    return Promise.resolve();
  }

  const feedIndex = feeds.value.findIndex((f) => f.feedId === feedId);
  if (feedIndex !== -1) {
    const feed = feeds.value[feedIndex]!;

    const newFeed = {
      name: `${feed.name} (copy)`,
      packageId: feed.packageId,
      feedId: -1,
    } as FeedInfo;

    feeds.value.splice(feedIndex, 0, newFeed);

    const response = await api.cloneFeed({ feedId, name: newFeed.name });

    if (response.data.error) {
      console.warn(
        `Failed to duplicate feed "${feed.name}"`,
        response.data.error,
      );

      // remove teh feed!
      feeds.value.splice(feedIndex, 1);
      return Promise.reject(response.data.error);
    }

    feeds.value[feedIndex]!.feedId = response.data.data!.feedId;
    return;
  }

  console.log("Feed not found");
  return Promise.resolve();
}

/**
 * Adds a newly created feed to the feeds list
 * without having to refetch the entire list of feeds.
 */
export function addNewlyCreatedFeed(
  newFeed: CreateFeedResponse,
  packageId: number,
  theme: string,
) {
  console.log("addNewlyCreatedFeed", newFeed);
  const feedInfo: FeedInfo = {
    name: newFeed.name,
    packageId: packageId,
    feedId: newFeed.feedId,
    accessKey: newFeed.accessKey,
    deliveryMethod: newFeed.deliveryMethod,
    moderationMethod: newFeed.moderationMethod ?? "None",
    url: newFeed.url,
    theme: theme,
    formatName: "",
    actualHeight: newFeed.actualHeight,
    actualWidth: newFeed.actualWidth,
  };

  // Immediately attempt to update the publish status (in case it's already available)
  const publishInfo = feedPublishQueue.value.find(
    (info) => Number(info.feedId) === newFeed.feedId,
  );

  if (publishInfo) {
    updateExternalFeedDetails(
      feedInfo,
      publishInfo.status,
      publishInfo.timestamp,
    );

    // Remove from feedPublishQueue
    feedPublishQueue.value = feedPublishQueue.value.filter(
      (info) => info.feedId !== publishInfo.feedId,
    );
  }

  feeds.value.push(feedInfo);
}

type FeedPublishInfo = {
  feedId: string;
  status: ExternalFeedStatus;
  timestamp: string;
};

const feedPublishQueue = ref<FeedPublishInfo[]>([]);

function updateExternalFeedDetails(
  feed: FeedInfo,
  status: ExternalFeedStatus,
  timestamp: string,
) {
  const exFeed = feed.externalFeeds?.[0];
  if (exFeed) {
    exFeed.status = status;
    exFeed.updatedOnUtc = timestamp;
  } else {
    const fakeExternalFeed = {
      status: status,
      updatedOnUtc: timestamp,
    } as ExternalFeed;

    feed.externalFeeds = [fakeExternalFeed];
  }
}

export function updateFeedPublishState(
  feedId: string,
  status: ExternalFeedStatus,
  timestamp: string,
) {
  const feed = feeds.value.find((f) => f.feedId === Number(feedId));
  console.log("updateFeedPublishState", { feedId, status, timestamp });

  if (feed) {
    updateExternalFeedDetails(feed, status, timestamp);
  } else {
    feedPublishQueue.value.push({ feedId, status, timestamp });
    console.log("Can't update publish state because feed isn't found yet");
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * AcctV2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccountContactForStoreDto
 */
export interface AccountContactForStoreDto {
    /**
     * 
     * @type {number}
     * @memberof AccountContactForStoreDto
     */
    'userId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountContactForStoreDto
     */
    'contactId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountContactForStoreDto
     */
    'isContactOnly'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountContactForStoreDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountContactForStoreDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountContactForStoreDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountContactForStoreDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccountContactForStoreDto
     */
    'isPrimaryContact'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountContactForStoreDto
     */
    'shouldReceiveAllEmails'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountContactForStoreDto
     */
    'shouldReceiveAccountAlertEmails'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountContactForStoreDto
     */
    'shouldReceiveBillingEmails'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountContactForStoreDto
     */
    'shouldReceiveIntegrationEmails'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountContactForStoreDto
     */
    'isUserArchived'?: boolean | null;
    /**
     * 
     * @type {Array<AppAccess>}
     * @memberof AccountContactForStoreDto
     */
    'userAppAccess'?: Array<AppAccess> | null;
}
/**
 * 
 * @export
 * @interface AccountContactForStoreDtoArrayGenericResponse
 */
export interface AccountContactForStoreDtoArrayGenericResponse {
    /**
     * 
     * @type {Array<AccountContactForStoreDto>}
     * @memberof AccountContactForStoreDtoArrayGenericResponse
     */
    'data'?: Array<AccountContactForStoreDto> | null;
    /**
     * 
     * @type {object}
     * @memberof AccountContactForStoreDtoArrayGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof AccountContactForStoreDtoArrayGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof AccountContactForStoreDtoArrayGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface AccountContactForStoreDtoGenericResponse
 */
export interface AccountContactForStoreDtoGenericResponse {
    /**
     * 
     * @type {AccountContactForStoreDto}
     * @memberof AccountContactForStoreDtoGenericResponse
     */
    'data'?: AccountContactForStoreDto;
    /**
     * 
     * @type {object}
     * @memberof AccountContactForStoreDtoGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof AccountContactForStoreDtoGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof AccountContactForStoreDtoGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'locality'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'region'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'postCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    'currencyId'?: number | null;
}
/**
 * 
 * @export
 * @interface AirportInfo
 */
export interface AirportInfo {
    /**
     * 
     * @type {string}
     * @memberof AirportInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AirportInfo
     */
    'iataCode': string;
    /**
     * 
     * @type {string}
     * @memberof AirportInfo
     */
    'icaoCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirportInfo
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof AirportInfo
     */
    'country': string;
    /**
     * 
     * @type {number}
     * @memberof AirportInfo
     */
    'popularity': number;
}
/**
 * 
 * @export
 * @interface AirportInfoArrayGenericResponse
 */
export interface AirportInfoArrayGenericResponse {
    /**
     * 
     * @type {Array<AirportInfo>}
     * @memberof AirportInfoArrayGenericResponse
     */
    'data'?: Array<AirportInfo> | null;
    /**
     * 
     * @type {object}
     * @memberof AirportInfoArrayGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof AirportInfoArrayGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof AirportInfoArrayGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface AppAccess
 */
export interface AppAccess {
    /**
     * 
     * @type {number}
     * @memberof AppAccess
     */
    'appId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AppAccess
     */
    'appName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AppAccess
     */
    'hasAccess'?: boolean;
}
/**
 * 
 * @export
 * @interface ArchiveCustomerRequest
 */
export interface ArchiveCustomerRequest {
    /**
     * 
     * @type {number}
     * @memberof ArchiveCustomerRequest
     */
    'customerAccountId'?: number;
}
/**
 * 
 * @export
 * @interface ArchiveCustomerResponseGenericResponse
 */
export interface ArchiveCustomerResponseGenericResponse {
    /**
     * 
     * @type {object}
     * @memberof ArchiveCustomerResponseGenericResponse
     */
    'data'?: object;
    /**
     * 
     * @type {object}
     * @memberof ArchiveCustomerResponseGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof ArchiveCustomerResponseGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof ArchiveCustomerResponseGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface ArchiveUserCommand
 */
export interface ArchiveUserCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof ArchiveUserCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof ArchiveUserCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ArchiveUserCommand
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ArchiveUserCommand
     */
    'newOwnerId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ArchiveUserCommand
     */
    'isArchived'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ArchiveUserCommand
     */
    'accountId'?: number | null;
}


/**
 * 
 * @export
 * @interface ArchiveUserCommandGenericResponse
 */
export interface ArchiveUserCommandGenericResponse {
    /**
     * 
     * @type {ArchiveUserCommand}
     * @memberof ArchiveUserCommandGenericResponse
     */
    'data'?: ArchiveUserCommand;
    /**
     * 
     * @type {object}
     * @memberof ArchiveUserCommandGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof ArchiveUserCommandGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof ArchiveUserCommandGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @enum {string}
 */

export type AuthType = "None" | "ApiKey" | "AccessKey" | "OAuth2";


/**
 * 
 * @export
 * @interface Authentication
 */
export interface Authentication {
    /**
     * 
     * @type {AuthType}
     * @memberof Authentication
     */
    'type'?: AuthType;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    'endpoint'?: string | null;
}


/**
 * 
 * @export
 * @interface Authorization
 */
export interface Authorization {
    /**
     * 
     * @type {number}
     * @memberof Authorization
     */
    'providerAuthId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Authorization
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Authorization
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface AuthorizationIEnumerableGenericResponse
 */
export interface AuthorizationIEnumerableGenericResponse {
    /**
     * 
     * @type {Array<Authorization>}
     * @memberof AuthorizationIEnumerableGenericResponse
     */
    'data'?: Array<Authorization> | null;
    /**
     * 
     * @type {object}
     * @memberof AuthorizationIEnumerableGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof AuthorizationIEnumerableGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof AuthorizationIEnumerableGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface Bill
 */
export interface Bill {
    /**
     * 
     * @type {number}
     * @memberof Bill
     */
    'billId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Bill
     */
    'currencyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    'dueOn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    'orderDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    'paidOn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    'licensedUntil'?: string;
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    'invoiceNumber'?: string | null;
    /**
     * 
     * @type {Array<Payment>}
     * @memberof Bill
     */
    'payments'?: Array<Payment> | null;
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    'status'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Bill
     */
    'totalDue'?: number;
    /**
     * 
     * @type {number}
     * @memberof Bill
     */
    'totalPayments'?: number;
    /**
     * 
     * @type {number}
     * @memberof Bill
     */
    'balance'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Bill
     */
    'isPaidInFull'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Bill
     */
    'isPaidInPart'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Bill
     */
    'isOverdue'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Bill
     */
    'daysOverdue'?: number;
}
/**
 * 
 * @export
 * @interface BillingSettings
 */
export interface BillingSettings {
    /**
     * 
     * @type {string}
     * @memberof BillingSettings
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingSettings
     */
    'frequency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BillingSettings
     */
    'frequencyMultiplier'?: number;
    /**
     * 
     * @type {number}
     * @memberof BillingSettings
     */
    'discount'?: number;
    /**
     * 
     * @type {number}
     * @memberof BillingSettings
     */
    'terms'?: number;
    /**
     * 
     * @type {number}
     * @memberof BillingSettings
     */
    'day'?: number;
    /**
     * 
     * @type {CreditCard}
     * @memberof BillingSettings
     */
    'card'?: CreditCard;
    /**
     * 
     * @type {InvoiceSettings}
     * @memberof BillingSettings
     */
    'invoiceSettings'?: InvoiceSettings;
    /**
     * 
     * @type {Array<string>}
     * @memberof BillingSettings
     */
    'emailRecipients'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface BrandedMenuInfo
 */
export interface BrandedMenuInfo {
    /**
     * 
     * @type {string}
     * @memberof BrandedMenuInfo
     */
    'subdomain'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandedMenuInfo
     */
    'logoUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandedMenuInfo
     */
    'bannerColor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandedMenuInfo
     */
    'accentColor'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof BrandedMenuInfo
     */
    'hiddenPackageIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export type CalendarAccessRole = "FreeBusyReader" | "Owner" | "Reader" | "Writer";


/**
 * 
 * @export
 * @interface CalendarDto
 */
export interface CalendarDto {
    /**
     * 
     * @type {string}
     * @memberof CalendarDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarDto
     */
    'location'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarDto
     */
    'summary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarDto
     */
    'backgroundColor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarDto
     */
    'foregroundColor'?: string | null;
}
/**
 * 
 * @export
 * @interface CalendarListDto
 */
export interface CalendarListDto {
    /**
     * 
     * @type {number}
     * @memberof CalendarListDto
     */
    'providerAuthId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarListDto
     */
    'nextPageToken'?: string | null;
    /**
     * 
     * @type {Array<CalendarDto>}
     * @memberof CalendarListDto
     */
    'calendars'?: Array<CalendarDto> | null;
}
/**
 * 
 * @export
 * @interface CalendarListDtoGenericResponse
 */
export interface CalendarListDtoGenericResponse {
    /**
     * 
     * @type {CalendarListDto}
     * @memberof CalendarListDtoGenericResponse
     */
    'data'?: CalendarListDto;
    /**
     * 
     * @type {object}
     * @memberof CalendarListDtoGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof CalendarListDtoGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof CalendarListDtoGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface CategoryItem
 */
export interface CategoryItem {
    /**
     * 
     * @type {string}
     * @memberof CategoryItem
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryItem
     */
    'label'?: string | null;
}
/**
 * 
 * @export
 * @interface CategoryItemArrayGenericResponse
 */
export interface CategoryItemArrayGenericResponse {
    /**
     * 
     * @type {Array<CategoryItem>}
     * @memberof CategoryItemArrayGenericResponse
     */
    'data'?: Array<CategoryItem> | null;
    /**
     * 
     * @type {object}
     * @memberof CategoryItemArrayGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof CategoryItemArrayGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof CategoryItemArrayGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface ChannelInfo
 */
export interface ChannelInfo {
    /**
     * 
     * @type {number}
     * @memberof ChannelInfo
     */
    'channelId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ChannelInfo
     */
    'isSelected'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChannelInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ChannelInfo
     */
    'itemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChannelInfo
     */
    'maxAllowed'?: number | null;
}
/**
 * 
 * @export
 * @interface ChannelSelectingSelectListItem
 */
export interface ChannelSelectingSelectListItem {
    /**
     * 
     * @type {string}
     * @memberof ChannelSelectingSelectListItem
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChannelSelectingSelectListItem
     */
    'value'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ChannelSelectingSelectListItem
     */
    'channelIds'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChannelSelectingSelectListItem
     */
    'customizable'?: boolean | null;
    /**
     * 
     * @type {{ [key: string]: object | null; }}
     * @memberof ChannelSelectingSelectListItem
     */
    'defaultValues'?: { [key: string]: object | null; } | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChannelSelectingSelectListItem
     */
    'deprecated'?: boolean | null;
}
/**
 * 
 * @export
 * @interface ClientAuthModelDto
 */
export interface ClientAuthModelDto {
    /**
     * 
     * @type {string}
     * @memberof ClientAuthModelDto
     */
    'oAuthEndpoint'?: string | null;
}
/**
 * 
 * @export
 * @interface ClientAuthModelDtoGenericResponse
 */
export interface ClientAuthModelDtoGenericResponse {
    /**
     * 
     * @type {ClientAuthModelDto}
     * @memberof ClientAuthModelDtoGenericResponse
     */
    'data'?: ClientAuthModelDto;
    /**
     * 
     * @type {object}
     * @memberof ClientAuthModelDtoGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof ClientAuthModelDtoGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof ClientAuthModelDtoGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface CloneFeedCommand
 */
export interface CloneFeedCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof CloneFeedCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof CloneFeedCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CloneFeedCommand
     */
    'feedId': number;
    /**
     * 
     * @type {string}
     * @memberof CloneFeedCommand
     */
    'name'?: string | null;
}


/**
 * 
 * @export
 * @interface CloneFeedCommandResponse
 */
export interface CloneFeedCommandResponse {
    /**
     * 
     * @type {number}
     * @memberof CloneFeedCommandResponse
     */
    'feedId': number;
}
/**
 * 
 * @export
 * @interface CloneFeedCommandResponseGenericResponse
 */
export interface CloneFeedCommandResponseGenericResponse {
    /**
     * 
     * @type {CloneFeedCommandResponse}
     * @memberof CloneFeedCommandResponseGenericResponse
     */
    'data'?: CloneFeedCommandResponse;
    /**
     * 
     * @type {object}
     * @memberof CloneFeedCommandResponseGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof CloneFeedCommandResponseGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof CloneFeedCommandResponseGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface Connection
 */
export interface Connection {
    /**
     * 
     * @type {string}
     * @memberof Connection
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof Connection
     */
    'authUsername'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Connection
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Connection
     */
    'source'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Connection
     */
    'isFaulted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Connection
     */
    'faultCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Connection
     */
    'faultMessage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Connection
     */
    'providerAuthId'?: number | null;
    /**
     * 
     * @type {Provider}
     * @memberof Connection
     */
    'provider'?: Provider;
}
/**
 * 
 * @export
 * @interface ConnectionContext
 */
export interface ConnectionContext {
    /**
     * 
     * @type {string}
     * @memberof ConnectionContext
     */
    'connectedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionContext
     */
    'userAgent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectionContext
     */
    'ipAddress'?: string | null;
}
/**
 * 
 * @export
 * @interface ConnectionContextGenericResponse
 */
export interface ConnectionContextGenericResponse {
    /**
     * 
     * @type {ConnectionContext}
     * @memberof ConnectionContextGenericResponse
     */
    'data'?: ConnectionContext;
    /**
     * 
     * @type {object}
     * @memberof ConnectionContextGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof ConnectionContextGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof ConnectionContextGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface ConnectionMetrics
 */
export interface ConnectionMetrics {
    /**
     * 
     * @type {number}
     * @memberof ConnectionMetrics
     */
    'totalChannels'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConnectionMetrics
     */
    'totalConnections'?: number;
    /**
     * 
     * @type {{ [key: string]: number | null; }}
     * @memberof ConnectionMetrics
     */
    'connectionsPerChannel'?: { [key: string]: number | null; } | null;
    /**
     * 
     * @type {{ [key: string]: Array<string> | null; }}
     * @memberof ConnectionMetrics
     */
    'connectionsToChannel'?: { [key: string]: Array<string> | null; } | null;
    /**
     * 
     * @type {number}
     * @memberof ConnectionMetrics
     */
    'totalContexts'?: number;
}
/**
 * 
 * @export
 * @interface ConnectionMetricsGenericResponse
 */
export interface ConnectionMetricsGenericResponse {
    /**
     * 
     * @type {ConnectionMetrics}
     * @memberof ConnectionMetricsGenericResponse
     */
    'data'?: ConnectionMetrics;
    /**
     * 
     * @type {object}
     * @memberof ConnectionMetricsGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof ConnectionMetricsGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof ConnectionMetricsGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface ConnectionProvider
 */
export interface ConnectionProvider {
    /**
     * 
     * @type {string}
     * @memberof ConnectionProvider
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectionProvider
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectionProvider
     */
    'provisionMethod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectionProvider
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectionProvider
     */
    'displayDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectionProvider
     */
    'isRefreshable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectionProvider
     */
    'canModerate'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConnectionProvider
     */
    'iconUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectionProvider
     */
    'supportArticleUrl'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConnectionProvider
     */
    'setupSteps'?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: string | null; }}
     * @memberof ConnectionProvider
     */
    'setupProperties'?: { [key: string]: string | null; } | null;
    /**
     * 
     * @type {SchemaType}
     * @memberof ConnectionProvider
     */
    'schemaType'?: SchemaType;
    /**
     * 
     * @type {Array<SetupStep>}
     * @memberof ConnectionProvider
     */
    'setupStepsV2'?: Array<SetupStep> | null;
    /**
     * 
     * @type {number}
     * @memberof ConnectionProvider
     */
    'providerDefinitionVersion'?: number | null;
    /**
     * 
     * @type {Documentation}
     * @memberof ConnectionProvider
     */
    'documentation'?: Documentation;
    /**
     * 
     * @type {Array<number>}
     * @memberof ConnectionProvider
     */
    'accountIds'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectionProvider
     */
    'isPublic'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectionProvider
     */
    'isConnectDataSource'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectionProvider
     */
    'isFeedConfigDataSource'?: boolean;
}


/**
 * 
 * @export
 * @interface ConnectionProviderArrayGenericResponse
 */
export interface ConnectionProviderArrayGenericResponse {
    /**
     * 
     * @type {Array<ConnectionProvider>}
     * @memberof ConnectionProviderArrayGenericResponse
     */
    'data'?: Array<ConnectionProvider> | null;
    /**
     * 
     * @type {object}
     * @memberof ConnectionProviderArrayGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof ConnectionProviderArrayGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof ConnectionProviderArrayGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'contactName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'website'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'locality'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'region'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'postCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'country'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateAccountFromAccountCommand
 */
export interface CreateAccountFromAccountCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof CreateAccountFromAccountCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof CreateAccountFromAccountCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountFromAccountCommand
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountFromAccountCommand
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountFromAccountCommand
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountFromAccountCommand
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountFromAccountCommand
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountFromAccountCommand
     */
    'returnUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountFromAccountCommand
     */
    'referrer'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountFromAccountCommand
     */
    'contactName'?: string | null;
}


/**
 * 
 * @export
 * @interface CreateAccountFromAccountResult
 */
export interface CreateAccountFromAccountResult {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountFromAccountResult
     */
    'oneTimeAccessUrl': string;
}
/**
 * 
 * @export
 * @interface CreateAccountFromAccountResultGenericResponse
 */
export interface CreateAccountFromAccountResultGenericResponse {
    /**
     * 
     * @type {CreateAccountFromAccountResult}
     * @memberof CreateAccountFromAccountResultGenericResponse
     */
    'data'?: CreateAccountFromAccountResult;
    /**
     * 
     * @type {object}
     * @memberof CreateAccountFromAccountResultGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof CreateAccountFromAccountResultGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof CreateAccountFromAccountResultGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface CreateChildAccountFromStoreCommand
 */
export interface CreateChildAccountFromStoreCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof CreateChildAccountFromStoreCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof CreateChildAccountFromStoreCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateChildAccountFromStoreCommand
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateChildAccountFromStoreCommand
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateChildAccountFromStoreCommand
     */
    'contactName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateChildAccountFromStoreCommand
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateChildAccountFromStoreCommand
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateChildAccountFromStoreCommand
     */
    'website'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateChildAccountFromStoreCommand
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateChildAccountFromStoreCommand
     */
    'locality'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateChildAccountFromStoreCommand
     */
    'region'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateChildAccountFromStoreCommand
     */
    'postCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateChildAccountFromStoreCommand
     */
    'country'?: string | null;
}


/**
 * 
 * @export
 * @interface CreateChildAccountResponse
 */
export interface CreateChildAccountResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateChildAccountResponse
     */
    'accountId'?: number;
}
/**
 * 
 * @export
 * @interface CreateChildAccountResponseGenericResponse
 */
export interface CreateChildAccountResponseGenericResponse {
    /**
     * 
     * @type {CreateChildAccountResponse}
     * @memberof CreateChildAccountResponseGenericResponse
     */
    'data'?: CreateChildAccountResponse;
    /**
     * 
     * @type {object}
     * @memberof CreateChildAccountResponseGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof CreateChildAccountResponseGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof CreateChildAccountResponseGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface CreateFeedRequest
 */
export interface CreateFeedRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateFeedRequest
     */
    'accountId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateFeedRequest
     */
    'packageId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateFeedRequest
     */
    'formatId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedRequest
     */
    'name': string;
    /**
     * 
     * @type {FeedDeliveryMethod}
     * @memberof CreateFeedRequest
     */
    'deliveryMethod'?: FeedDeliveryMethod;
    /**
     * 
     * @type {FeedModerationMethod}
     * @memberof CreateFeedRequest
     */
    'moderationMethod'?: FeedModerationMethod;
    /**
     * 
     * @type {Array<ChannelInfo>}
     * @memberof CreateFeedRequest
     */
    'channels'?: Array<ChannelInfo> | null;
    /**
     * 
     * @type {Array<DataConnection>}
     * @memberof CreateFeedRequest
     */
    'connections'?: Array<DataConnection> | null;
    /**
     * 
     * @type {{ [key: string]: string | null; }}
     * @memberof CreateFeedRequest
     */
    'options'?: { [key: string]: string | null; } | null;
}


/**
 * 
 * @export
 * @interface CreateFeedResponse
 */
export interface CreateFeedResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateFeedResponse
     */
    'feedId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedResponse
     */
    'accessKey': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateFeedResponse
     */
    'actualWidth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateFeedResponse
     */
    'actualHeight'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedResponse
     */
    'deliveryMethod': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedResponse
     */
    'url': string;
    /**
     * 
     * @type {FeedModerationMethod}
     * @memberof CreateFeedResponse
     */
    'moderationMethod'?: FeedModerationMethod;
    /**
     * 
     * @type {boolean}
     * @memberof CreateFeedResponse
     */
    'requiresSetup'?: boolean;
}


/**
 * 
 * @export
 * @interface CreateFeedResponseGenericResponse
 */
export interface CreateFeedResponseGenericResponse {
    /**
     * 
     * @type {CreateFeedResponse}
     * @memberof CreateFeedResponseGenericResponse
     */
    'data'?: CreateFeedResponse;
    /**
     * 
     * @type {object}
     * @memberof CreateFeedResponseGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof CreateFeedResponseGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof CreateFeedResponseGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface CreateSocialSearchCommand
 */
export interface CreateSocialSearchCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof CreateSocialSearchCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof CreateSocialSearchCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateSocialSearchCommand
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSocialSearchCommand
     */
    'externalCustomerId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateSocialSearchCommand
     */
    'providerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSocialSearchCommand
     */
    'authId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateSocialSearchCommand
     */
    'query'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateSocialSearchCommand
     */
    'displayName'?: string | null;
}


/**
 * 
 * @export
 * @interface CreateUserFromStoreCommand
 */
export interface CreateUserFromStoreCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof CreateUserFromStoreCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof CreateUserFromStoreCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateUserFromStoreCommand
     */
    'accountId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserFromStoreCommand
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserFromStoreCommand
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserFromStoreCommand
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserFromStoreCommand
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserFromStoreCommand
     */
    'password'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserFromStoreCommand
     */
    'isPrimaryContact'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserFromStoreCommand
     */
    'shouldReceiveAllEmails'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserFromStoreCommand
     */
    'shouldReceiveAccountAlertEmails'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserFromStoreCommand
     */
    'shouldReceiveBillingEmails'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserFromStoreCommand
     */
    'shouldReceiveIntegrationEmails'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserFromStoreCommand
     */
    'shouldSendInviteEmail'?: boolean;
    /**
     * 
     * @type {Array<AppAccess>}
     * @memberof CreateUserFromStoreCommand
     */
    'appAccess'?: Array<AppAccess> | null;
}


/**
 * 
 * @export
 * @interface CreateUserMagicInviteLinkCommand
 */
export interface CreateUserMagicInviteLinkCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof CreateUserMagicInviteLinkCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof CreateUserMagicInviteLinkCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateUserMagicInviteLinkCommand
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateUserMagicInviteLinkCommand
     */
    'userId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserMagicInviteLinkCommand
     */
    'shouldSendInviteEmail'?: boolean;
}


/**
 * 
 * @export
 * @interface CreateUserMagicLinkInviteResult
 */
export interface CreateUserMagicLinkInviteResult {
    /**
     * 
     * @type {string}
     * @memberof CreateUserMagicLinkInviteResult
     */
    'lifetime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserMagicLinkInviteResult
     */
    'link'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateUserMagicLinkInviteResultGenericResponse
 */
export interface CreateUserMagicLinkInviteResultGenericResponse {
    /**
     * 
     * @type {CreateUserMagicLinkInviteResult}
     * @memberof CreateUserMagicLinkInviteResultGenericResponse
     */
    'data'?: CreateUserMagicLinkInviteResult;
    /**
     * 
     * @type {object}
     * @memberof CreateUserMagicLinkInviteResultGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof CreateUserMagicLinkInviteResultGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof CreateUserMagicLinkInviteResultGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface CreditCard
 */
export interface CreditCard {
    /**
     * 
     * @type {number}
     * @memberof CreditCard
     */
    'creditCardId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    'cardType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    'billToName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    'lastFour'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreditCard
     */
    'expireMonth'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditCard
     */
    'expireYear'?: number;
    /**
     * 
     * @type {Address}
     * @memberof CreditCard
     */
    'billingAddress'?: Address;
}
/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    'isArchived'?: boolean;
}
/**
 * 
 * @export
 * @interface DataConnection
 */
export interface DataConnection {
    /**
     * 
     * @type {string}
     * @memberof DataConnection
     */
    'uuid'?: string;
}
/**
 * 
 * @export
 * @interface DefineSchemaStep
 */
export interface DefineSchemaStep {
    /**
     * 
     * @type {number}
     * @memberof DefineSchemaStep
     */
    'order'?: number;
    /**
     * 
     * @type {string}
     * @memberof DefineSchemaStep
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DefineSchemaStep
     */
    'clientComponentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DefineSchemaStep
     */
    'displayNameI18nKey'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DefineSchemaStep
     */
    'shouldPersistConnection'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DefineSchemaStep
     */
    'shouldShowInNavigation'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DefineSchemaStep
     */
    'model'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof DefineSchemaStep
     */
    'Order'?: number;
    /**
     * 
     * @type {string}
     * @memberof DefineSchemaStep
     */
    'ClientComponentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DefineSchemaStep
     */
    'DisplayNameI18nKey'?: string | null;
    /**
     * 
     * @type {DefineSchemaType}
     * @memberof DefineSchemaStep
     */
    'Type'?: DefineSchemaType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export type DefineSchemaType = "Tabular" | "Tree" | "Manual" | "Calendar";


/**
 * 
 * @export
 * @interface DeliveryMethodsConfig
 */
export interface DeliveryMethodsConfig {
    /**
     * 
     * @type {FeedDeliveryMethod}
     * @memberof DeliveryMethodsConfig
     */
    'defaultDeliveryMethod'?: FeedDeliveryMethod;
    /**
     * 
     * @type {Array<FeedDeliveryMethod>}
     * @memberof DeliveryMethodsConfig
     */
    'deliveryMethodOrder'?: Array<FeedDeliveryMethod> | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryMethodsConfig
     */
    'additionalMessage'?: string | null;
}


/**
 * 
 * @export
 * @interface Documentation
 */
export interface Documentation {
    /**
     * 
     * @type {string}
     * @memberof Documentation
     */
    'clientComponentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Documentation
     */
    'url'?: string | null;
}
/**
 * 
 * @export
 * @interface EmbedObjectDto
 */
export interface EmbedObjectDto {
    /**
     * 
     * @type {string}
     * @memberof EmbedObjectDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmbedObjectDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmbedObjectDto
     */
    'embedUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmbedObjectDto
     */
    'webUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface EmbedObjectDtoIEnumerableGenericResponse
 */
export interface EmbedObjectDtoIEnumerableGenericResponse {
    /**
     * 
     * @type {Array<EmbedObjectDto>}
     * @memberof EmbedObjectDtoIEnumerableGenericResponse
     */
    'data'?: Array<EmbedObjectDto> | null;
    /**
     * 
     * @type {object}
     * @memberof EmbedObjectDtoIEnumerableGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof EmbedObjectDtoIEnumerableGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof EmbedObjectDtoIEnumerableGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface EmptyResponseGenericResponse
 */
export interface EmptyResponseGenericResponse {
    /**
     * 
     * @type {object}
     * @memberof EmptyResponseGenericResponse
     */
    'data'?: object;
    /**
     * 
     * @type {object}
     * @memberof EmptyResponseGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof EmptyResponseGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof EmptyResponseGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface ErrorDetail
 */
export interface ErrorDetail {
    /**
     * 
     * @type {string}
     * @memberof ErrorDetail
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ErrorDetail
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface ExternalFeed
 */
export interface ExternalFeed {
    /**
     * 
     * @type {number}
     * @memberof ExternalFeed
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ExternalFeed
     */
    'providerSlug': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalFeed
     */
    'providerDisplayName': string;
    /**
     * 
     * @type {ExternalFeedStatus}
     * @memberof ExternalFeed
     */
    'status': ExternalFeedStatus;
    /**
     * 
     * @type {string}
     * @memberof ExternalFeed
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalFeed
     */
    'updatedOnUtc'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export type ExternalFeedStatus = "Pending" | "Published" | "Error" | "None";


/**
 * 
 * @export
 * @interface Feed
 */
export interface Feed {
    /**
     * 
     * @type {number}
     * @memberof Feed
     */
    'feedId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Feed
     */
    'accessKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Feed
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Feed
     */
    'actualWidth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Feed
     */
    'actualHeight'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Feed
     */
    'deliveryMethod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Feed
     */
    'url'?: string | null;
    /**
     * 
     * @type {FeedModerationMethod}
     * @memberof Feed
     */
    'moderationMethod'?: FeedModerationMethod;
    /**
     * 
     * @type {boolean}
     * @memberof Feed
     */
    'requiresSetup'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Feed
     */
    'formatName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Feed
     */
    'theme'?: string | null;
}


/**
 * 
 * @export
 * @interface FeedCreationChannel
 */
export interface FeedCreationChannel {
    /**
     * 
     * @type {number}
     * @memberof FeedCreationChannel
     */
    'channelId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FeedCreationChannel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeedCreationChannel
     */
    'contentType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FeedCreationChannel
     */
    'itemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FeedCreationChannel
     */
    'maxAllowed'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FeedCreationChannel
     */
    'isSelected'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FeedCreationChannel
     */
    'culture'?: string | null;
}
/**
 * 
 * @export
 * @interface FeedCreationFormat
 */
export interface FeedCreationFormat {
    /**
     * 
     * @type {number}
     * @memberof FeedCreationFormat
     */
    'formatId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FeedCreationFormat
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeedCreationFormat
     */
    'aspectRatio'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FeedCreationFormat
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof FeedCreationFormat
     */
    'height'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof FeedCreationFormat
     */
    'channelIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface FeedCreationOption
 */
export interface FeedCreationOption {
    /**
     * 
     * @type {any}
     * @memberof FeedCreationOption
     */
    'defaultValue'?: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof FeedCreationOption
     */
    'required'?: boolean | null;
    /**
     * 
     * @type {Array<SelectListItem>}
     * @memberof FeedCreationOption
     */
    'selectList'?: Array<SelectListItem> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FeedCreationOption
     */
    'onlyForChannelIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface FeedCreationOptions
 */
export interface FeedCreationOptions {
    /**
     * 
     * @type {Array<number>}
     * @memberof FeedCreationOptions
     */
    'channelIds'?: Array<number> | null;
    /**
     * 
     * @type {{ [key: string]: FeedCreationOption; }}
     * @memberof FeedCreationOptions
     */
    'options'?: { [key: string]: FeedCreationOption; } | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export type FeedDeliveryMethod = "MediaRss" | "Html" | "DirectUrl" | "Flash" | "Json" | "Xml";


/**
 * 
 * @export
 * @interface FeedInfo
 */
export interface FeedInfo {
    /**
     * 
     * @type {number}
     * @memberof FeedInfo
     */
    'feedId': number;
    /**
     * 
     * @type {number}
     * @memberof FeedInfo
     */
    'packageId': number;
    /**
     * 
     * @type {string}
     * @memberof FeedInfo
     */
    'accessKey': string;
    /**
     * 
     * @type {string}
     * @memberof FeedInfo
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof FeedInfo
     */
    'actualWidth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FeedInfo
     */
    'actualHeight'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FeedInfo
     */
    'deliveryMethod': string;
    /**
     * 
     * @type {string}
     * @memberof FeedInfo
     */
    'url': string;
    /**
     * 
     * @type {FeedModerationMethod}
     * @memberof FeedInfo
     */
    'moderationMethod': FeedModerationMethod;
    /**
     * 
     * @type {string}
     * @memberof FeedInfo
     */
    'formatName': string;
    /**
     * 
     * @type {string}
     * @memberof FeedInfo
     */
    'theme': string;
    /**
     * 
     * @type {Array<ExternalFeed>}
     * @memberof FeedInfo
     */
    'externalFeeds'?: Array<ExternalFeed> | null;
}


/**
 * 
 * @export
 * @interface FeedInfoArrayGenericResponse
 */
export interface FeedInfoArrayGenericResponse {
    /**
     * 
     * @type {Array<FeedInfo>}
     * @memberof FeedInfoArrayGenericResponse
     */
    'data'?: Array<FeedInfo> | null;
    /**
     * 
     * @type {object}
     * @memberof FeedInfoArrayGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof FeedInfoArrayGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof FeedInfoArrayGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @enum {string}
 */

export type FeedModerationMethod = "Approve" | "Remove" | "None";


/**
 * 
 * @export
 * @interface ForgotPasswordRequest
 */
export interface ForgotPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordRequest
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordRequest
     */
    'returnUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface GenericResponse
 */
export interface GenericResponse {
    /**
     * 
     * @type {object}
     * @memberof GenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof GenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof GenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface GetAccountContactsForStoreQuery
 */
export interface GetAccountContactsForStoreQuery {
    /**
     * 
     * @type {number}
     * @memberof GetAccountContactsForStoreQuery
     */
    'accountId'?: number;
}
/**
 * 
 * @export
 * @interface GetAccountRequest
 */
export interface GetAccountRequest {
    /**
     * 
     * @type {number}
     * @memberof GetAccountRequest
     */
    'accountId'?: number | null;
}
/**
 * 
 * @export
 * @interface GetAccountResponse
 */
export interface GetAccountResponse {
    /**
     * 
     * @type {string}
     * @memberof GetAccountResponse
     */
    'guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAccountResponse
     */
    'accountType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAccountResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetAccountResponse
     */
    'currencyId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetAccountResponse
     */
    'isArchived'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAccountResponse
     */
    'isSuperReseller'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAccountResponse
     */
    'isDelinquent'?: boolean;
    /**
     * 
     * @type {BillingSettings}
     * @memberof GetAccountResponse
     */
    'billingInfo'?: BillingSettings;
    /**
     * 
     * @type {Contact}
     * @memberof GetAccountResponse
     */
    'contactInfo'?: Contact;
    /**
     * 
     * @type {BrandedMenuInfo}
     * @memberof GetAccountResponse
     */
    'brandedMenu'?: BrandedMenuInfo;
    /**
     * 
     * @type {MetaInfo}
     * @memberof GetAccountResponse
     */
    'meta'?: MetaInfo;
    /**
     * 
     * @type {Array<Customer>}
     * @memberof GetAccountResponse
     */
    'customers'?: Array<Customer> | null;
    /**
     * 
     * @type {Array<SubscriptionInfo>}
     * @memberof GetAccountResponse
     */
    'subscriptions'?: Array<SubscriptionInfo> | null;
    /**
     * 
     * @type {Array<Feed>}
     * @memberof GetAccountResponse
     */
    'feedGroups'?: Array<Feed> | null;
    /**
     * 
     * @type {Array<Bill>}
     * @memberof GetAccountResponse
     */
    'bills'?: Array<Bill> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetAccountResponse
     */
    'languageFilters'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetAccountResponse
     */
    'hasParentAccount'?: boolean;
    /**
     * 
     * @type {Array<AppAccess>}
     * @memberof GetAccountResponse
     */
    'accountAppAccess'?: Array<AppAccess> | null;
    /**
     * 
     * @type {number}
     * @memberof GetAccountResponse
     */
    'accountId'?: number;
}
/**
 * 
 * @export
 * @interface GetAccountResponseGenericResponse
 */
export interface GetAccountResponseGenericResponse {
    /**
     * 
     * @type {GetAccountResponse}
     * @memberof GetAccountResponseGenericResponse
     */
    'data'?: GetAccountResponse;
    /**
     * 
     * @type {object}
     * @memberof GetAccountResponseGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof GetAccountResponseGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof GetAccountResponseGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface GetAirportAutocompleteRequest
 */
export interface GetAirportAutocompleteRequest {
    /**
     * 
     * @type {string}
     * @memberof GetAirportAutocompleteRequest
     */
    'query': string;
}
/**
 * 
 * @export
 * @interface GetCalendarsCommand
 */
export interface GetCalendarsCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof GetCalendarsCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof GetCalendarsCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCalendarsCommand
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetCalendarsCommand
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetCalendarsCommand
     */
    'providerAuthId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCalendarsCommand
     */
    'resultsPerPage'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetCalendarsCommand
     */
    'nextPageToken'?: string | null;
    /**
     * 
     * @type {CalendarAccessRole}
     * @memberof GetCalendarsCommand
     */
    'minAccessRole'?: CalendarAccessRole;
}


/**
 * 
 * @export
 * @interface GetContentMenuQuery
 */
export interface GetContentMenuQuery {
    /**
     * 
     * @type {number}
     * @memberof GetContentMenuQuery
     */
    'accountId'?: number | null;
}
/**
 * 
 * @export
 * @interface GetCustomersRequest
 */
export interface GetCustomersRequest {
    /**
     * 
     * @type {number}
     * @memberof GetCustomersRequest
     */
    'accountId'?: number | null;
}
/**
 * 
 * @export
 * @interface GetCustomersResponse
 */
export interface GetCustomersResponse {
    /**
     * 
     * @type {Array<Customer>}
     * @memberof GetCustomersResponse
     */
    'customers'?: Array<Customer> | null;
}
/**
 * 
 * @export
 * @interface GetCustomersResponseGenericResponse
 */
export interface GetCustomersResponseGenericResponse {
    /**
     * 
     * @type {GetCustomersResponse}
     * @memberof GetCustomersResponseGenericResponse
     */
    'data'?: GetCustomersResponse;
    /**
     * 
     * @type {object}
     * @memberof GetCustomersResponseGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof GetCustomersResponseGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof GetCustomersResponseGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface GetFeedConfigRequest
 */
export interface GetFeedConfigRequest {
    /**
     * 
     * @type {number}
     * @memberof GetFeedConfigRequest
     */
    'feedId'?: number;
}
/**
 * 
 * @export
 * @interface GetFeedConfigResponse
 */
export interface GetFeedConfigResponse {
    /**
     * 
     * @type {number}
     * @memberof GetFeedConfigResponse
     */
    'feedId': number;
    /**
     * 
     * @type {string}
     * @memberof GetFeedConfigResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof GetFeedConfigResponse
     */
    'formatId': number;
    /**
     * 
     * @type {number}
     * @memberof GetFeedConfigResponse
     */
    'packageId': number;
    /**
     * 
     * @type {string}
     * @memberof GetFeedConfigResponse
     */
    'packageName': string;
    /**
     * 
     * @type {number}
     * @memberof GetFeedConfigResponse
     */
    'productId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedConfigResponse
     */
    'productSlug': string;
    /**
     * 
     * @type {string}
     * @memberof GetFeedConfigResponse
     */
    'previewKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedConfigResponse
     */
    'htmlUrl': string;
    /**
     * 
     * @type {string}
     * @memberof GetFeedConfigResponse
     */
    'dataUrl': string;
    /**
     * 
     * @type {FeedDeliveryMethod}
     * @memberof GetFeedConfigResponse
     */
    'deliveryMethod'?: FeedDeliveryMethod;
    /**
     * 
     * @type {FeedModerationMethod}
     * @memberof GetFeedConfigResponse
     */
    'moderationMethod'?: FeedModerationMethod;
    /**
     * 
     * @type {{ [key: string]: string | null; }}
     * @memberof GetFeedConfigResponse
     */
    'options'?: { [key: string]: string | null; } | null;
    /**
     * 
     * @type {Array<FeedCreationChannel>}
     * @memberof GetFeedConfigResponse
     */
    'channels'?: Array<FeedCreationChannel> | null;
    /**
     * 
     * @type {Array<FeedCreationFormat>}
     * @memberof GetFeedConfigResponse
     */
    'formats'?: Array<FeedCreationFormat> | null;
    /**
     * 
     * @type {{ [key: string]: FeedCreationOptions; }}
     * @memberof GetFeedConfigResponse
     */
    'deliveryMethods'?: { [key: string]: FeedCreationOptions; } | null;
    /**
     * 
     * @type {DeliveryMethodsConfig}
     * @memberof GetFeedConfigResponse
     */
    'deliveryMethodsConfig'?: DeliveryMethodsConfig;
    /**
     * 
     * @type {string}
     * @memberof GetFeedConfigResponse
     */
    'feedUrl': string;
}


/**
 * 
 * @export
 * @interface GetFeedConfigResponseGenericResponse
 */
export interface GetFeedConfigResponseGenericResponse {
    /**
     * 
     * @type {GetFeedConfigResponse}
     * @memberof GetFeedConfigResponseGenericResponse
     */
    'data'?: GetFeedConfigResponse;
    /**
     * 
     * @type {object}
     * @memberof GetFeedConfigResponseGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof GetFeedConfigResponseGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof GetFeedConfigResponseGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface GetFeedConnectionsResponse
 */
export interface GetFeedConnectionsResponse {
    /**
     * 
     * @type {Array<Connection>}
     * @memberof GetFeedConnectionsResponse
     */
    'feedConnections'?: Array<Connection> | null;
}
/**
 * 
 * @export
 * @interface GetFeedConnectionsResponseGenericResponse
 */
export interface GetFeedConnectionsResponseGenericResponse {
    /**
     * 
     * @type {GetFeedConnectionsResponse}
     * @memberof GetFeedConnectionsResponseGenericResponse
     */
    'data'?: GetFeedConnectionsResponse;
    /**
     * 
     * @type {object}
     * @memberof GetFeedConnectionsResponseGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof GetFeedConnectionsResponseGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof GetFeedConnectionsResponseGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface GetFeedCreationRequest
 */
export interface GetFeedCreationRequest {
    /**
     * 
     * @type {number}
     * @memberof GetFeedCreationRequest
     */
    'accountId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedCreationRequest
     */
    'packageId'?: number;
}
/**
 * 
 * @export
 * @interface GetFeedCreationResponse
 */
export interface GetFeedCreationResponse {
    /**
     * 
     * @type {string}
     * @memberof GetFeedCreationResponse
     */
    'packageName': string;
    /**
     * 
     * @type {number}
     * @memberof GetFeedCreationResponse
     */
    'productId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedCreationResponse
     */
    'productSlug': string;
    /**
     * 
     * @type {string}
     * @memberof GetFeedCreationResponse
     */
    'htmlUrl': string;
    /**
     * 
     * @type {string}
     * @memberof GetFeedCreationResponse
     */
    'dataUrl': string;
    /**
     * 
     * @type {Array<FeedCreationChannel>}
     * @memberof GetFeedCreationResponse
     */
    'channels'?: Array<FeedCreationChannel> | null;
    /**
     * 
     * @type {Array<FeedCreationFormat>}
     * @memberof GetFeedCreationResponse
     */
    'formats'?: Array<FeedCreationFormat> | null;
    /**
     * 
     * @type {{ [key: string]: FeedCreationOptions; }}
     * @memberof GetFeedCreationResponse
     */
    'deliveryMethods'?: { [key: string]: FeedCreationOptions; } | null;
    /**
     * 
     * @type {DeliveryMethodsConfig}
     * @memberof GetFeedCreationResponse
     */
    'deliveryMethodsConfig'?: DeliveryMethodsConfig;
}
/**
 * 
 * @export
 * @interface GetFeedCreationResponseGenericResponse
 */
export interface GetFeedCreationResponseGenericResponse {
    /**
     * 
     * @type {GetFeedCreationResponse}
     * @memberof GetFeedCreationResponseGenericResponse
     */
    'data'?: GetFeedCreationResponse;
    /**
     * 
     * @type {object}
     * @memberof GetFeedCreationResponseGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof GetFeedCreationResponseGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof GetFeedCreationResponseGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface GetFeedDataConnectionsQuery
 */
export interface GetFeedDataConnectionsQuery {
    /**
     * 
     * @type {number}
     * @memberof GetFeedDataConnectionsQuery
     */
    'feedId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetFeedDataConnectionsQuery
     */
    'accountId'?: number | null;
}
/**
 * 
 * @export
 * @interface GetFeedsQuery
 */
export interface GetFeedsQuery {
    /**
     * 
     * @type {number}
     * @memberof GetFeedsQuery
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetFeedsQuery
     */
    'packageId'?: number | null;
}
/**
 * 
 * @export
 * @interface GetMicrosoftCalendarsCommand
 */
export interface GetMicrosoftCalendarsCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof GetMicrosoftCalendarsCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof GetMicrosoftCalendarsCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetMicrosoftCalendarsCommand
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMicrosoftCalendarsCommand
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMicrosoftCalendarsCommand
     */
    'providerAuthId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetMicrosoftCalendarsCommand
     */
    'resultsPerPage'?: number | null;
}


/**
 * 
 * @export
 * @interface GetPowerBIDashboardsRequest
 */
export interface GetPowerBIDashboardsRequest {
    /**
     * 
     * @type {number}
     * @memberof GetPowerBIDashboardsRequest
     */
    'providerAuthorizationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPowerBIDashboardsRequest
     */
    'workspaceId'?: string | null;
}
/**
 * 
 * @export
 * @interface GetPowerBIReportsRequest
 */
export interface GetPowerBIReportsRequest {
    /**
     * 
     * @type {number}
     * @memberof GetPowerBIReportsRequest
     */
    'providerAuthorizationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPowerBIReportsRequest
     */
    'workspaceId'?: string | null;
}
/**
 * 
 * @export
 * @interface GetPowerBITilesRequest
 */
export interface GetPowerBITilesRequest {
    /**
     * 
     * @type {number}
     * @memberof GetPowerBITilesRequest
     */
    'providerAuthorizationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPowerBITilesRequest
     */
    'workspaceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPowerBITilesRequest
     */
    'dashboardId'?: string | null;
}
/**
 * 
 * @export
 * @interface GetPowerBIWorkspacesRequest
 */
export interface GetPowerBIWorkspacesRequest {
    /**
     * 
     * @type {number}
     * @memberof GetPowerBIWorkspacesRequest
     */
    'providerAuthorizationId'?: number;
}
/**
 * 
 * @export
 * @interface GetSportsLeaguesResponse
 */
export interface GetSportsLeaguesResponse {
    /**
     * 
     * @type {Array<League>}
     * @memberof GetSportsLeaguesResponse
     */
    'leagues': Array<League>;
}
/**
 * 
 * @export
 * @interface GetSportsLeaguesResponseGenericResponse
 */
export interface GetSportsLeaguesResponseGenericResponse {
    /**
     * 
     * @type {GetSportsLeaguesResponse}
     * @memberof GetSportsLeaguesResponseGenericResponse
     */
    'data'?: GetSportsLeaguesResponse;
    /**
     * 
     * @type {object}
     * @memberof GetSportsLeaguesResponseGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof GetSportsLeaguesResponseGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof GetSportsLeaguesResponseGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface GetSportsTeamsRequest
 */
export interface GetSportsTeamsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSportsTeamsRequest
     */
    'leagues'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof GetSportsTeamsRequest
     */
    'location'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSportsTeamsRequest
     */
    'query'?: string | null;
}
/**
 * 
 * @export
 * @interface GetSportsTeamsResponse
 */
export interface GetSportsTeamsResponse {
    /**
     * 
     * @type {Array<Team>}
     * @memberof GetSportsTeamsResponse
     */
    'teams': Array<Team>;
}
/**
 * 
 * @export
 * @interface GetSportsTeamsResponseGenericResponse
 */
export interface GetSportsTeamsResponseGenericResponse {
    /**
     * 
     * @type {GetSportsTeamsResponse}
     * @memberof GetSportsTeamsResponseGenericResponse
     */
    'data'?: GetSportsTeamsResponse;
    /**
     * 
     * @type {object}
     * @memberof GetSportsTeamsResponseGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof GetSportsTeamsResponseGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof GetSportsTeamsResponseGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface GetStockSymbolsRequest
 */
export interface GetStockSymbolsRequest {
    /**
     * 
     * @type {string}
     * @memberof GetStockSymbolsRequest
     */
    'query': string;
    /**
     * 
     * @type {string}
     * @memberof GetStockSymbolsRequest
     */
    'exchange'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetStockSymbolsRequest
     */
    'limit'?: number | null;
}
/**
 * 
 * @export
 * @interface GetValidLocationQuery
 */
export interface GetValidLocationQuery {
    /**
     * 
     * @type {number}
     * @memberof GetValidLocationQuery
     */
    'accountId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetValidLocationQuery
     */
    'location': string;
    /**
     * 
     * @type {number}
     * @memberof GetValidLocationQuery
     */
    'packageId': number;
}
/**
 * 
 * @export
 * @interface InvoiceSettings
 */
export interface InvoiceSettings {
    /**
     * 
     * @type {string}
     * @memberof InvoiceSettings
     */
    'invoicePONumber'?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof InvoiceSettings
     */
    'billingAddress'?: Address;
}
/**
 * 
 * @export
 * @interface League
 */
export interface League {
    /**
     * 
     * @type {string}
     * @memberof League
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof League
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof League
     */
    'logoUrl': string;
    /**
     * 
     * @type {string}
     * @memberof League
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof League
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof League
     */
    'start'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof League
     */
    'end'?: string | null;
}
/**
 * 
 * @export
 * @interface MetaInfo
 */
export interface MetaInfo {
    /**
     * 
     * @type {number}
     * @memberof MetaInfo
     */
    'viewers'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MetaInfo
     */
    'venue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetaInfo
     */
    'dwellTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetaInfo
     */
    'software'?: string | null;
}
/**
 * 
 * @export
 * @interface NameStep
 */
export interface NameStep {
    /**
     * 
     * @type {number}
     * @memberof NameStep
     */
    'order'?: number;
    /**
     * 
     * @type {string}
     * @memberof NameStep
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NameStep
     */
    'clientComponentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NameStep
     */
    'displayNameI18nKey'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NameStep
     */
    'shouldPersistConnection'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NameStep
     */
    'shouldShowInNavigation'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof NameStep
     */
    'model'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof NameStep
     */
    'Order'?: number;
    /**
     * 
     * @type {string}
     * @memberof NameStep
     */
    'ClientComponentName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NameStep
     */
    'ShouldPersistConnection'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NameStep
     */
    'DisplayNameI18nKey'?: string | null;
}
/**
 * 
 * @export
 * @interface NotificationPayload
 */
export interface NotificationPayload {
    /**
     * 
     * @type {string}
     * @memberof NotificationPayload
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationPayload
     */
    'scope'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationPayload
     */
    'level'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationPayload
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationPayload
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationPayload
     */
    'body'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationPayload
     */
    'link'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationPayload
     */
    'entityType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationPayload
     */
    'entityId'?: string | null;
}
/**
 * 
 * @export
 * @interface NotificationPayloadGenericResponse
 */
export interface NotificationPayloadGenericResponse {
    /**
     * 
     * @type {NotificationPayload}
     * @memberof NotificationPayloadGenericResponse
     */
    'data'?: NotificationPayload;
    /**
     * 
     * @type {object}
     * @memberof NotificationPayloadGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof NotificationPayloadGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof NotificationPayloadGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface PackageInfo
 */
export interface PackageInfo {
    /**
     * 
     * @type {number}
     * @memberof PackageInfo
     */
    'packageId': number;
    /**
     * 
     * @type {string}
     * @memberof PackageInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PackageInfo
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof PackageInfo
     */
    'thumbnail'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PackageInfo
     */
    'categories': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PackageInfo
     */
    'feedCount': number;
    /**
     * 
     * @type {number}
     * @memberof PackageInfo
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof PackageInfo
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<SpecIconWidget>}
     * @memberof PackageInfo
     */
    'widgets'?: Array<SpecIconWidget> | null;
}
/**
 * 
 * @export
 * @interface PackageInfoArrayGenericResponse
 */
export interface PackageInfoArrayGenericResponse {
    /**
     * 
     * @type {Array<PackageInfo>}
     * @memberof PackageInfoArrayGenericResponse
     */
    'data'?: Array<PackageInfo> | null;
    /**
     * 
     * @type {object}
     * @memberof PackageInfoArrayGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof PackageInfoArrayGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof PackageInfoArrayGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'paymentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'datePaid'?: string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'tender'?: string | null;
}
/**
 * 
 * @export
 * @interface ProvideStep
 */
export interface ProvideStep {
    /**
     * 
     * @type {number}
     * @memberof ProvideStep
     */
    'order'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProvideStep
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProvideStep
     */
    'clientComponentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProvideStep
     */
    'displayNameI18nKey'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProvideStep
     */
    'shouldPersistConnection'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProvideStep
     */
    'shouldShowInNavigation'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProvideStep
     */
    'model'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof ProvideStep
     */
    'Order'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProvideStep
     */
    'ClientComponentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProvideStep
     */
    'DisplayNameI18nKey'?: string | null;
    /**
     * 
     * @type {Authentication}
     * @memberof ProvideStep
     */
    'Authenticator'?: Authentication;
    /**
     * 
     * @type {Array<object>}
     * @memberof ProvideStep
     */
    'Inputs'?: Array<object> | null;
    /**
     * 
     * @type {string}
     * @memberof ProvideStep
     */
    'Endpoint'?: string | null;
}
/**
 * 
 * @export
 * @interface Provider
 */
export interface Provider {
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'providerSlug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    'iconUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface RemoveFeedRequest
 */
export interface RemoveFeedRequest {
    /**
     * 
     * @type {number}
     * @memberof RemoveFeedRequest
     */
    'feedId'?: number;
}
/**
 * 
 * @export
 * @interface RequiredFeedCreationOption
 */
export interface RequiredFeedCreationOption {
    /**
     * 
     * @type {any}
     * @memberof RequiredFeedCreationOption
     */
    'defaultValue'?: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFeedCreationOption
     */
    'required'?: boolean | null;
    /**
     * 
     * @type {Array<SelectListItem>}
     * @memberof RequiredFeedCreationOption
     */
    'selectList'?: Array<SelectListItem> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RequiredFeedCreationOption
     */
    'onlyForChannelIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'securityToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'newPassword'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export type SchemaType = "Undefined" | "Tabular" | "Tree" | "Calendar";


/**
 * 
 * @export
 * @enum {string}
 */

export type ScreenfeedApp = "Unknown" | "Kitchen" | "RoboChef" | "BackEnd" | "OpenId" | "Ops" | "Store" | "ContentCloud" | "Oven" | "Microwave" | "Util" | "HealthInspector" | "Test" | "SwfGenerator" | "Companion" | "LoadTester" | "Connect" | "Acct" | "Integrators" | "Img" | "Yarp";


/**
 * 
 * @export
 * @interface SelectListItem
 */
export interface SelectListItem {
    /**
     * 
     * @type {string}
     * @memberof SelectListItem
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SelectListItem
     */
    'value'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof SelectListItem
     */
    'channelIds'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof SelectListItem
     */
    'customizable'?: boolean | null;
    /**
     * 
     * @type {{ [key: string]: any | null; }}
     * @memberof SelectListItem
     */
    'defaultValues'?: { [key: string]: any | null; } | null;
    /**
     * 
     * @type {boolean}
     * @memberof SelectListItem
     */
    'deprecated'?: boolean | null;
}
/**
 * 
 * @export
 * @interface SendVerifyCodeCommand
 */
export interface SendVerifyCodeCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof SendVerifyCodeCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof SendVerifyCodeCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SendVerifyCodeCommand
     */
    'userId'?: number;
}


/**
 * 
 * @export
 * @interface SendVerifyCodeCommandGenericResponse
 */
export interface SendVerifyCodeCommandGenericResponse {
    /**
     * 
     * @type {SendVerifyCodeCommand}
     * @memberof SendVerifyCodeCommandGenericResponse
     */
    'data'?: SendVerifyCodeCommand;
    /**
     * 
     * @type {object}
     * @memberof SendVerifyCodeCommandGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof SendVerifyCodeCommandGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof SendVerifyCodeCommandGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface SetupStep
 */
export interface SetupStep {
    /**
     * 
     * @type {number}
     * @memberof SetupStep
     */
    'order'?: number;
    /**
     * 
     * @type {string}
     * @memberof SetupStep
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetupStep
     */
    'clientComponentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetupStep
     */
    'displayNameI18nKey'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SetupStep
     */
    'shouldPersistConnection'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SetupStep
     */
    'shouldShowInNavigation'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SetupStep
     */
    'model'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface SocialAuthorization
 */
export interface SocialAuthorization {
    /**
     * 
     * @type {number}
     * @memberof SocialAuthorization
     */
    'authId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SocialAuthorization
     */
    'searchId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SocialAuthorization
     */
    'query'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SocialAuthorization
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SocialAuthorization
     */
    'providerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SocialAuthorization
     */
    'providerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SocialAuthorization
     */
    'providerUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface SocialAuthorizationGenericResponse
 */
export interface SocialAuthorizationGenericResponse {
    /**
     * 
     * @type {SocialAuthorization}
     * @memberof SocialAuthorizationGenericResponse
     */
    'data'?: SocialAuthorization;
    /**
     * 
     * @type {object}
     * @memberof SocialAuthorizationGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof SocialAuthorizationGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof SocialAuthorizationGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface SocialAuthorizationListGenericResponse
 */
export interface SocialAuthorizationListGenericResponse {
    /**
     * 
     * @type {Array<SocialAuthorization>}
     * @memberof SocialAuthorizationListGenericResponse
     */
    'data'?: Array<SocialAuthorization> | null;
    /**
     * 
     * @type {object}
     * @memberof SocialAuthorizationListGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof SocialAuthorizationListGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof SocialAuthorizationListGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface SpecIconWidget
 */
export interface SpecIconWidget {
    /**
     * 
     * @type {string}
     * @memberof SpecIconWidget
     */
    'spec'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpecIconWidget
     */
    'caption'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpecIconWidget
     */
    'icon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpecIconWidget
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface StockSymbol
 */
export interface StockSymbol {
    /**
     * 
     * @type {string}
     * @memberof StockSymbol
     */
    'symbol': string;
    /**
     * 
     * @type {string}
     * @memberof StockSymbol
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StockSymbol
     */
    'exchangeSymbol': string;
}
/**
 * 
 * @export
 * @interface StockSymbolArrayGenericResponse
 */
export interface StockSymbolArrayGenericResponse {
    /**
     * 
     * @type {Array<StockSymbol>}
     * @memberof StockSymbolArrayGenericResponse
     */
    'data'?: Array<StockSymbol> | null;
    /**
     * 
     * @type {object}
     * @memberof StockSymbolArrayGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof StockSymbolArrayGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof StockSymbolArrayGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface SubscriptionInfo
 */
export interface SubscriptionInfo {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionInfo
     */
    'subscriptionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionInfo
     */
    'packageId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionInfo
     */
    'packageName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionInfo
     */
    'packageSlug'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionInfo
     */
    'isCustomPrice'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionInfo
     */
    'cost'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionInfo
     */
    'numberOfLicenses'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionInfo
     */
    'billRecurringly'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionInfo
     */
    'pO'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionInfo
     */
    'expireDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionInfo
     */
    'numberOfMonths'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionInfo
     */
    'isTrial'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionInfo
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionInfo
     */
    'paymentStatusId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionInfo
     */
    'currentBreakPrice'?: number;
}
/**
 * 
 * @export
 * @interface SyncStep
 */
export interface SyncStep {
    /**
     * 
     * @type {number}
     * @memberof SyncStep
     */
    'order'?: number;
    /**
     * 
     * @type {string}
     * @memberof SyncStep
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SyncStep
     */
    'clientComponentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SyncStep
     */
    'displayNameI18nKey'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SyncStep
     */
    'shouldPersistConnection'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyncStep
     */
    'shouldShowInNavigation'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SyncStep
     */
    'model'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof SyncStep
     */
    'Order'?: number;
    /**
     * 
     * @type {string}
     * @memberof SyncStep
     */
    'ClientComponentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SyncStep
     */
    'DisplayNameI18nKey'?: string | null;
}
/**
 * 
 * @export
 * @interface Team
 */
export interface Team {
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'leagueId': string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'logoUrl': string;
}
/**
 * 
 * @export
 * @interface ThemeSelectListItem
 */
export interface ThemeSelectListItem {
    /**
     * 
     * @type {string}
     * @memberof ThemeSelectListItem
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ThemeSelectListItem
     */
    'value'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ThemeSelectListItem
     */
    'channelIds'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ThemeSelectListItem
     */
    'customizable'?: boolean | null;
    /**
     * 
     * @type {{ [key: string]: object | null; }}
     * @memberof ThemeSelectListItem
     */
    'defaultValues'?: { [key: string]: object | null; } | null;
    /**
     * 
     * @type {boolean}
     * @memberof ThemeSelectListItem
     */
    'deprecated'?: boolean | null;
}
/**
 * 
 * @export
 * @interface UnarchiveCustomerRequest
 */
export interface UnarchiveCustomerRequest {
    /**
     * 
     * @type {number}
     * @memberof UnarchiveCustomerRequest
     */
    'customerAccountId'?: number;
}
/**
 * 
 * @export
 * @interface UnarchiveCustomerResponseGenericResponse
 */
export interface UnarchiveCustomerResponseGenericResponse {
    /**
     * 
     * @type {object}
     * @memberof UnarchiveCustomerResponseGenericResponse
     */
    'data'?: object;
    /**
     * 
     * @type {object}
     * @memberof UnarchiveCustomerResponseGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof UnarchiveCustomerResponseGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof UnarchiveCustomerResponseGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface UpdateConnectionAuthCommand
 */
export interface UpdateConnectionAuthCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof UpdateConnectionAuthCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof UpdateConnectionAuthCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateConnectionAuthCommand
     */
    'accountId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateConnectionAuthCommand
     */
    'providerAuthId'?: number;
}


/**
 * 
 * @export
 * @interface UpdateCustomerRequest
 */
export interface UpdateCustomerRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateCustomerRequest
     */
    'customerAccountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerRequest
     */
    'customerName'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateCustomerResponseGenericResponse
 */
export interface UpdateCustomerResponseGenericResponse {
    /**
     * 
     * @type {object}
     * @memberof UpdateCustomerResponseGenericResponse
     */
    'data'?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdateCustomerResponseGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof UpdateCustomerResponseGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof UpdateCustomerResponseGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface UpdateFeedAccessKeyCommand
 */
export interface UpdateFeedAccessKeyCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof UpdateFeedAccessKeyCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedAccessKeyCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedAccessKeyCommand
     */
    'feedId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFeedAccessKeyCommand
     */
    'IsUpdate'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedAccessKeyCommand
     */
    'AccessKey'?: string | null;
}


/**
 * 
 * @export
 * @interface UpdateFeedDeliveryMethodCommand
 */
export interface UpdateFeedDeliveryMethodCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof UpdateFeedDeliveryMethodCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedDeliveryMethodCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedDeliveryMethodCommand
     */
    'feedId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedDeliveryMethodCommand
     */
    'DeliveryMethod'?: string | null;
}


/**
 * 
 * @export
 * @interface UpdateFeedFormatCommand
 */
export interface UpdateFeedFormatCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof UpdateFeedFormatCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedFormatCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedFormatCommand
     */
    'feedId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedFormatCommand
     */
    'FormatId'?: number;
}


/**
 * 
 * @export
 * @interface UpdateFeedIgnoreExpirationCommand
 */
export interface UpdateFeedIgnoreExpirationCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof UpdateFeedIgnoreExpirationCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedIgnoreExpirationCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedIgnoreExpirationCommand
     */
    'feedId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFeedIgnoreExpirationCommand
     */
    'IgnoreExpiration'?: boolean;
}


/**
 * 
 * @export
 * @interface UpdateFeedIncludeTextCommand
 */
export interface UpdateFeedIncludeTextCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof UpdateFeedIncludeTextCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedIncludeTextCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedIncludeTextCommand
     */
    'feedId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFeedIncludeTextCommand
     */
    'IncludeText'?: boolean;
}


/**
 * 
 * @export
 * @interface UpdateFeedIsArchivedCommand
 */
export interface UpdateFeedIsArchivedCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof UpdateFeedIsArchivedCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedIsArchivedCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedIsArchivedCommand
     */
    'feedId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFeedIsArchivedCommand
     */
    'IsArchived'?: boolean;
}


/**
 * 
 * @export
 * @interface UpdateFeedLanguageCommand
 */
export interface UpdateFeedLanguageCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof UpdateFeedLanguageCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedLanguageCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedLanguageCommand
     */
    'feedId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedLanguageCommand
     */
    'Language'?: string | null;
}


/**
 * 
 * @export
 * @interface UpdateFeedLocationCommand
 */
export interface UpdateFeedLocationCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof UpdateFeedLocationCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedLocationCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedLocationCommand
     */
    'feedId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedLocationCommand
     */
    'Location'?: string | null;
}


/**
 * 
 * @export
 * @interface UpdateFeedNameCommand
 */
export interface UpdateFeedNameCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof UpdateFeedNameCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedNameCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedNameCommand
     */
    'feedId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedNameCommand
     */
    'Name'?: string | null;
}


/**
 * 
 * @export
 * @interface UpdateFeedPropertyCommand
 */
export interface UpdateFeedPropertyCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof UpdateFeedPropertyCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedPropertyCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedPropertyCommand
     */
    'feedId'?: number;
}


/**
 * 
 * @export
 * @interface UpdateFeedRemoveMediaCommand
 */
export interface UpdateFeedRemoveMediaCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof UpdateFeedRemoveMediaCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedRemoveMediaCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedRemoveMediaCommand
     */
    'feedId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFeedRemoveMediaCommand
     */
    'RemoveMedia'?: boolean;
}


/**
 * 
 * @export
 * @interface UpdateFeedRequest
 */
export interface UpdateFeedRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedRequest
     */
    'feedId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedRequest
     */
    'formatId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedRequest
     */
    'name': string;
    /**
     * 
     * @type {FeedDeliveryMethod}
     * @memberof UpdateFeedRequest
     */
    'deliveryMethod'?: FeedDeliveryMethod;
    /**
     * 
     * @type {FeedModerationMethod}
     * @memberof UpdateFeedRequest
     */
    'moderationMethod'?: FeedModerationMethod;
    /**
     * 
     * @type {Array<ChannelInfo>}
     * @memberof UpdateFeedRequest
     */
    'channels'?: Array<ChannelInfo> | null;
    /**
     * 
     * @type {Array<DataConnection>}
     * @memberof UpdateFeedRequest
     */
    'connections'?: Array<DataConnection> | null;
    /**
     * 
     * @type {{ [key: string]: string | null; }}
     * @memberof UpdateFeedRequest
     */
    'options'?: { [key: string]: string | null; } | null;
}


/**
 * 
 * @export
 * @interface UpdateFeedResponse
 */
export interface UpdateFeedResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedResponse
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedResponse
     */
    'deliveryMethod': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedResponse
     */
    'feedId': number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFeedResponse
     */
    'requiresSetup': boolean;
}
/**
 * 
 * @export
 * @interface UpdateFeedResponseGenericResponse
 */
export interface UpdateFeedResponseGenericResponse {
    /**
     * 
     * @type {UpdateFeedResponse}
     * @memberof UpdateFeedResponseGenericResponse
     */
    'data'?: UpdateFeedResponse;
    /**
     * 
     * @type {object}
     * @memberof UpdateFeedResponseGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof UpdateFeedResponseGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof UpdateFeedResponseGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface UpdateFeedThemeCommand
 */
export interface UpdateFeedThemeCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof UpdateFeedThemeCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedThemeCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedThemeCommand
     */
    'feedId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedThemeCommand
     */
    'Theme'?: string | null;
}


/**
 * 
 * @export
 * @interface UpdateUserFromStoreCommand
 */
export interface UpdateUserFromStoreCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof UpdateUserFromStoreCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserFromStoreCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserFromStoreCommand
     */
    'accountId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserFromStoreCommand
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserFromStoreCommand
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserFromStoreCommand
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserFromStoreCommand
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserFromStoreCommand
     */
    'password'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserFromStoreCommand
     */
    'isPrimaryContact'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserFromStoreCommand
     */
    'shouldReceiveAllEmails'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserFromStoreCommand
     */
    'shouldReceiveAccountAlertEmails'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserFromStoreCommand
     */
    'shouldReceiveBillingEmails'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserFromStoreCommand
     */
    'shouldReceiveIntegrationEmails'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserFromStoreCommand
     */
    'shouldSendInviteEmail'?: boolean;
    /**
     * 
     * @type {Array<AppAccess>}
     * @memberof UpdateUserFromStoreCommand
     */
    'appAccess'?: Array<AppAccess> | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserFromStoreCommand
     */
    'UserId'?: number;
}


/**
 * 
 * @export
 * @interface UploadFeedLogoResponse
 */
export interface UploadFeedLogoResponse {
    /**
     * 
     * @type {string}
     * @memberof UploadFeedLogoResponse
     */
    'imageUrl': string;
    /**
     * 
     * @type {number}
     * @memberof UploadFeedLogoResponse
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof UploadFeedLogoResponse
     */
    'height': number;
}
/**
 * 
 * @export
 * @interface UploadFeedLogoResponseGenericResponse
 */
export interface UploadFeedLogoResponseGenericResponse {
    /**
     * 
     * @type {UploadFeedLogoResponse}
     * @memberof UploadFeedLogoResponseGenericResponse
     */
    'data'?: UploadFeedLogoResponse;
    /**
     * 
     * @type {object}
     * @memberof UploadFeedLogoResponseGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof UploadFeedLogoResponseGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof UploadFeedLogoResponseGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface ValidLocation
 */
export interface ValidLocation {
    /**
     * 
     * @type {string}
     * @memberof ValidLocation
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidLocation
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ValidLocation
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof ValidLocation
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof ValidLocation
     */
    'stateFull': string;
    /**
     * 
     * @type {string}
     * @memberof ValidLocation
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof ValidLocation
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof ValidLocation
     */
    'nearestZip': string;
    /**
     * 
     * @type {number}
     * @memberof ValidLocation
     */
    'lat': number;
    /**
     * 
     * @type {number}
     * @memberof ValidLocation
     */
    'lon': number;
    /**
     * 
     * @type {string}
     * @memberof ValidLocation
     */
    'timeZone': string;
    /**
     * 
     * @type {string}
     * @memberof ValidLocation
     */
    'ianaTimeZone': string;
    /**
     * 
     * @type {number}
     * @memberof ValidLocation
     */
    'timeZoneOffset': number;
    /**
     * 
     * @type {string}
     * @memberof ValidLocation
     */
    'localTime': string;
    /**
     * 
     * @type {boolean}
     * @memberof ValidLocation
     */
    'isUnknown': boolean;
    /**
     * 
     * @type {{ [key: string]: string | null; }}
     * @memberof ValidLocation
     */
    'options': { [key: string]: string | null; };
}
/**
 * 
 * @export
 * @interface ValidateLocationResponse
 */
export interface ValidateLocationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ValidateLocationResponse
     */
    'valid': boolean;
    /**
     * 
     * @type {string}
     * @memberof ValidateLocationResponse
     */
    'message'?: string | null;
    /**
     * 
     * @type {ValidLocation}
     * @memberof ValidateLocationResponse
     */
    'location'?: ValidLocation;
}
/**
 * 
 * @export
 * @interface ValidateLocationResponseGenericResponse
 */
export interface ValidateLocationResponseGenericResponse {
    /**
     * 
     * @type {ValidateLocationResponse}
     * @memberof ValidateLocationResponseGenericResponse
     */
    'data'?: ValidateLocationResponse;
    /**
     * 
     * @type {object}
     * @memberof ValidateLocationResponseGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof ValidateLocationResponseGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof ValidateLocationResponseGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface VerifyCodeCommand
 */
export interface VerifyCodeCommand {
    /**
     * 
     * @type {ScreenfeedApp}
     * @memberof VerifyCodeCommand
     */
    'app'?: ScreenfeedApp;
    /**
     * 
     * @type {number}
     * @memberof VerifyCodeCommand
     */
    'actingUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VerifyCodeCommand
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof VerifyCodeCommand
     */
    'code': string;
}


/**
 * 
 * @export
 * @interface VerifyCodeCommandGenericResponse
 */
export interface VerifyCodeCommandGenericResponse {
    /**
     * 
     * @type {VerifyCodeCommand}
     * @memberof VerifyCodeCommandGenericResponse
     */
    'data'?: VerifyCodeCommand;
    /**
     * 
     * @type {object}
     * @memberof VerifyCodeCommandGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof VerifyCodeCommandGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof VerifyCodeCommandGenericResponse
     */
    'error'?: ErrorDetail;
}
/**
 * 
 * @export
 * @interface WorkspaceDto
 */
export interface WorkspaceDto {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkspaceDtoIEnumerableGenericResponse
 */
export interface WorkspaceDtoIEnumerableGenericResponse {
    /**
     * 
     * @type {Array<WorkspaceDto>}
     * @memberof WorkspaceDtoIEnumerableGenericResponse
     */
    'data'?: Array<WorkspaceDto> | null;
    /**
     * 
     * @type {object}
     * @memberof WorkspaceDtoIEnumerableGenericResponse
     */
    'meta'?: object | null;
    /**
     * 
     * @type {Array<ErrorDetail>}
     * @memberof WorkspaceDtoIEnumerableGenericResponse
     */
    'errors'?: Array<ErrorDetail> | null;
    /**
     * 
     * @type {ErrorDetail}
     * @memberof WorkspaceDtoIEnumerableGenericResponse
     */
    'error'?: ErrorDetail;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ArchiveCustomerRequest} [archiveCustomerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveCustomer: async (archiveCustomerRequest?: ArchiveCustomerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/ArchiveCustomer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(archiveCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ArchiveUserCommand} [archiveUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveUser: async (archiveUserCommand?: ArchiveUserCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/ArchiveUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(archiveUserCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CloneFeedCommand} [cloneFeedCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneFeed: async (cloneFeedCommand?: CloneFeedCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/CloneFeed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cloneFeedCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAccountFromAccountCommand} [createAccountFromAccountCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount: async (createAccountFromAccountCommand?: CreateAccountFromAccountCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/CreateAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccountFromAccountCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateChildAccountFromStoreCommand} [createChildAccountFromStoreCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer: async (createChildAccountFromStoreCommand?: CreateChildAccountFromStoreCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/CreateCustomer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createChildAccountFromStoreCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateFeedRequest} [createFeedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeed: async (createFeedRequest?: CreateFeedRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/CreateFeed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFeedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSocialSearchCommand} [createSocialSearchCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSocialSearch: async (createSocialSearchCommand?: CreateSocialSearchCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/CreateSocialSearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSocialSearchCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserFromStoreCommand} [createUserFromStoreCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (createUserFromStoreCommand?: CreateUserFromStoreCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/CreateUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserFromStoreCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserMagicInviteLinkCommand} [createUserMagicInviteLinkCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserMagicInviteLink: async (createUserMagicInviteLinkCommand?: CreateUserMagicInviteLinkCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/CreateUserMagicInviteLink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserMagicInviteLinkCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgotPasswordRequest} [forgotPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: async (forgotPasswordRequest?: ForgotPasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/ForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetAccountRequest} [getAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount: async (getAccountRequest?: GetAccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetAccountContactsForStoreQuery} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountContact: async (query?: GetAccountContactsForStoreQuery, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetAccountContact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                for (const [key, value] of Object.entries(query)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetAccountContactsForStoreQuery} [getAccountContactsForStoreQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountContacts: async (getAccountContactsForStoreQuery?: GetAccountContactsForStoreQuery, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetAccountContacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getAccountContactsForStoreQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetAirportAutocompleteRequest} [getAirportAutocompleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAirportAutocomplete: async (getAirportAutocompleteRequest?: GetAirportAutocompleteRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetAirportAutocomplete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getAirportAutocompleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {string} [providerSlug] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthedUsersForProvider: async (accountId?: number, providerSlug?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetAuthedUsersForProvider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (providerSlug !== undefined) {
                localVarQueryParameter['providerSlug'] = providerSlug;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [providerId] 
         * @param {number} [accountId] 
         * @param {string} [clientUrl] 
         * @param {string} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizationModel: async (providerId?: number, accountId?: number, clientUrl?: string, version?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetAuthorizationModel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (providerId !== undefined) {
                localVarQueryParameter['ProviderId'] = providerId;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['AccountId'] = accountId;
            }

            if (clientUrl !== undefined) {
                localVarQueryParameter['ClientUrl'] = clientUrl;
            }

            if (version !== undefined) {
                localVarQueryParameter['Version'] = version;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} connectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionInfo: async (connectionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectionId' is not null or undefined
            assertParamExists('getConnectionInfo', 'connectionId', connectionId)
            const localVarPath = `/acct/v2/GetConnectionInfo/connection/{connectionId}`
                .replace(`{${"connectionId"}}`, encodeURIComponent(String(connectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionMetrics: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetConnectionMetrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {Array<string>} [providerSlugs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionProvidersBySlug: async (accountId?: number, providerSlugs?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetConnectionProvidersBySlug`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (providerSlugs) {
                localVarQueryParameter['providerSlugs'] = providerSlugs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] Optional Bearer token
         * @param {GetContentMenuQuery} [getContentMenuQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentMenu: async (authorization?: string, getContentMenuQuery?: GetContentMenuQuery, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetContentMenu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getContentMenuQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetCustomersRequest} [getCustomersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomers: async (getCustomersRequest?: GetCustomersRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetCustomers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getCustomersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetFeedConfigRequest} [getFeedConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedConfig: async (getFeedConfigRequest?: GetFeedConfigRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetFeedConfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getFeedConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetFeedDataConnectionsQuery} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedConnections: async (query?: GetFeedDataConnectionsQuery, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetFeedConnections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                for (const [key, value] of Object.entries(query)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] Optional Bearer token
         * @param {GetFeedCreationRequest} [getFeedCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedCreation: async (authorization?: string, getFeedCreationRequest?: GetFeedCreationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetFeedCreation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getFeedCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetFeedsQuery} [getFeedsQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeeds: async (getFeedsQuery?: GetFeedsQuery, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetFeeds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getFeedsQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetCalendarsCommand} [command] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoogleCalendars: async (command?: GetCalendarsCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetGoogleCalendars`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (command !== undefined) {
                for (const [key, value] of Object.entries(command)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalFavoritesCategories: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetLocalFavoritesCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetMicrosoftCalendarsCommand} [command] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMicrosoftCalendars: async (command?: GetMicrosoftCalendarsCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetMicrosoftCalendars`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (command !== undefined) {
                for (const [key, value] of Object.entries(command)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetPowerBIDashboardsRequest} [getPowerBIDashboardsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowerBIDashboards: async (getPowerBIDashboardsRequest?: GetPowerBIDashboardsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetPowerBIDashboards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPowerBIDashboardsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetPowerBIReportsRequest} [getPowerBIReportsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowerBIReports: async (getPowerBIReportsRequest?: GetPowerBIReportsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetPowerBIReports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPowerBIReportsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetPowerBITilesRequest} [getPowerBITilesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowerBITiles: async (getPowerBITilesRequest?: GetPowerBITilesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetPowerBITiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPowerBITilesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetPowerBIWorkspacesRequest} [getPowerBIWorkspacesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowerBIWorkspaces: async (getPowerBIWorkspacesRequest?: GetPowerBIWorkspacesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetPowerBIWorkspaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPowerBIWorkspacesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [externalCustomerId] 
         * @param {Array<number>} [providers] 
         * @param {Array<number>} [searches] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSocialSearchAuthorizations: async (accountId?: number, externalCustomerId?: number, providers?: Array<number>, searches?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetSocialSearchAuthorizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['AccountId'] = accountId;
            }

            if (externalCustomerId !== undefined) {
                localVarQueryParameter['ExternalCustomerId'] = externalCustomerId;
            }

            if (providers) {
                localVarQueryParameter['Providers'] = providers;
            }

            if (searches) {
                localVarQueryParameter['Searches'] = searches;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSportsLeagues: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetSportsLeagues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetSportsTeamsRequest} [getSportsTeamsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSportsTeams: async (getSportsTeamsRequest?: GetSportsTeamsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetSportsTeams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getSportsTeamsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetStockSymbolsRequest} [getStockSymbolsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockSymbols: async (getStockSymbolsRequest?: GetStockSymbolsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/GetStockSymbols`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getStockSymbolsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RemoveFeedRequest} [removeFeedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFeed: async (removeFeedRequest?: RemoveFeedRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/RemoveFeed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeFeedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateFeedNameCommand} [updateFeedNameCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameFeed: async (updateFeedNameCommand?: UpdateFeedNameCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/RenameFeed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFeedNameCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (resetPasswordRequest?: ResetPasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/ResetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendVerifyCode: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/SendVerifyCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simulateDownload: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/SimulateDownload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeAccountNotifications: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/SubscribeAccountNotifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeToTestNotifications: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/SubscribeToTestNotifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeUserNotifications: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/SubscribeUserNotifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isError] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testSignageLivePublish: async (isError?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/TestSignageLivePublish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isError !== undefined) {
                localVarQueryParameter['isError'] = isError;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UnarchiveCustomerRequest} [unarchiveCustomerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveCustomer: async (unarchiveCustomerRequest?: UnarchiveCustomerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/UnarchiveCustomer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unarchiveCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateConnectionAuthCommand} [command] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionAuth: async (command?: UpdateConnectionAuthCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/UpdateConnectionAuth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (command !== undefined) {
                for (const [key, value] of Object.entries(command)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCustomerRequest} [updateCustomerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomer: async (updateCustomerRequest?: UpdateCustomerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/UpdateCustomer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateFeedRequest} [updateFeedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeed: async (updateFeedRequest?: UpdateFeedRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/UpdateFeed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFeedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserFromStoreCommand} [updateUserFromStoreCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (updateUserFromStoreCommand?: UpdateUserFromStoreCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/UpdateUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserFromStoreCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file The logo file to upload
         * @param {string} [originalFileName] Original name of the file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLogo: async (file: File, originalFileName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadLogo', 'file', file)
            const localVarPath = `/acct/v2/UploadLogo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (originalFileName !== undefined) { 
                localVarFormParams.append('originalFileName', originalFileName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] Optional Bearer token
         * @param {GetValidLocationQuery} [getValidLocationQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateLocation: async (authorization?: string, getValidLocationQuery?: GetValidLocationQuery, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/ValidateLocation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getValidLocationQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyCodeCommand} [verifyCodeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyCode: async (verifyCodeCommand?: VerifyCodeCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acct/v2/VerifyCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyCodeCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ArchiveCustomerRequest} [archiveCustomerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveCustomer(archiveCustomerRequest?: ArchiveCustomerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArchiveCustomerResponseGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveCustomer(archiveCustomerRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.archiveCustomer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ArchiveUserCommand} [archiveUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveUser(archiveUserCommand?: ArchiveUserCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArchiveUserCommandGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveUser(archiveUserCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.archiveUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CloneFeedCommand} [cloneFeedCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloneFeed(cloneFeedCommand?: CloneFeedCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CloneFeedCommandResponseGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cloneFeed(cloneFeedCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.cloneFeed']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateAccountFromAccountCommand} [createAccountFromAccountCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccount(createAccountFromAccountCommand?: CreateAccountFromAccountCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAccountFromAccountResultGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccount(createAccountFromAccountCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateChildAccountFromStoreCommand} [createChildAccountFromStoreCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomer(createChildAccountFromStoreCommand?: CreateChildAccountFromStoreCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateChildAccountResponseGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomer(createChildAccountFromStoreCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createCustomer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateFeedRequest} [createFeedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFeed(createFeedRequest?: CreateFeedRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateFeedResponseGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFeed(createFeedRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createFeed']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateSocialSearchCommand} [createSocialSearchCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSocialSearch(createSocialSearchCommand?: CreateSocialSearchCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SocialAuthorizationGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSocialSearch(createSocialSearchCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createSocialSearch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateUserFromStoreCommand} [createUserFromStoreCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(createUserFromStoreCommand?: CreateUserFromStoreCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountContactForStoreDtoGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(createUserFromStoreCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateUserMagicInviteLinkCommand} [createUserMagicInviteLinkCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserMagicInviteLink(createUserMagicInviteLinkCommand?: CreateUserMagicInviteLinkCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUserMagicLinkInviteResultGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserMagicInviteLink(createUserMagicInviteLinkCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createUserMagicInviteLink']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ForgotPasswordRequest} [forgotPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(forgotPasswordRequest?: ForgotPasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPassword(forgotPasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.forgotPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetAccountRequest} [getAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccount(getAccountRequest?: GetAccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAccountResponseGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccount(getAccountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetAccountContactsForStoreQuery} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountContact(query?: GetAccountContactsForStoreQuery, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountContactForStoreDtoGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountContact(query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getAccountContact']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetAccountContactsForStoreQuery} [getAccountContactsForStoreQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountContacts(getAccountContactsForStoreQuery?: GetAccountContactsForStoreQuery, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountContactForStoreDtoArrayGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountContacts(getAccountContactsForStoreQuery, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getAccountContacts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetAirportAutocompleteRequest} [getAirportAutocompleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAirportAutocomplete(getAirportAutocompleteRequest?: GetAirportAutocompleteRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AirportInfoArrayGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAirportAutocomplete(getAirportAutocompleteRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getAirportAutocomplete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {string} [providerSlug] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthedUsersForProvider(accountId?: number, providerSlug?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizationIEnumerableGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthedUsersForProvider(accountId, providerSlug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getAuthedUsersForProvider']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [providerId] 
         * @param {number} [accountId] 
         * @param {string} [clientUrl] 
         * @param {string} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthorizationModel(providerId?: number, accountId?: number, clientUrl?: string, version?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientAuthModelDtoGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthorizationModel(providerId, accountId, clientUrl, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getAuthorizationModel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} connectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectionInfo(connectionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectionContextGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectionInfo(connectionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getConnectionInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectionMetrics(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectionMetricsGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectionMetrics(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getConnectionMetrics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {Array<string>} [providerSlugs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectionProvidersBySlug(accountId?: number, providerSlugs?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectionProviderArrayGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectionProvidersBySlug(accountId, providerSlugs, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getConnectionProvidersBySlug']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] Optional Bearer token
         * @param {GetContentMenuQuery} [getContentMenuQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentMenu(authorization?: string, getContentMenuQuery?: GetContentMenuQuery, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackageInfoArrayGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentMenu(authorization, getContentMenuQuery, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getContentMenu']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetCustomersRequest} [getCustomersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomers(getCustomersRequest?: GetCustomersRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCustomersResponseGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomers(getCustomersRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getCustomers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetFeedConfigRequest} [getFeedConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeedConfig(getFeedConfigRequest?: GetFeedConfigRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFeedConfigResponseGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeedConfig(getFeedConfigRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getFeedConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetFeedDataConnectionsQuery} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeedConnections(query?: GetFeedDataConnectionsQuery, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFeedConnectionsResponseGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeedConnections(query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getFeedConnections']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] Optional Bearer token
         * @param {GetFeedCreationRequest} [getFeedCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeedCreation(authorization?: string, getFeedCreationRequest?: GetFeedCreationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFeedCreationResponseGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeedCreation(authorization, getFeedCreationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getFeedCreation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetFeedsQuery} [getFeedsQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeeds(getFeedsQuery?: GetFeedsQuery, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedInfoArrayGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeeds(getFeedsQuery, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getFeeds']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetCalendarsCommand} [command] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGoogleCalendars(command?: GetCalendarsCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarListDtoGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGoogleCalendars(command, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getGoogleCalendars']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocalFavoritesCategories(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryItemArrayGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocalFavoritesCategories(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getLocalFavoritesCategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetMicrosoftCalendarsCommand} [command] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMicrosoftCalendars(command?: GetMicrosoftCalendarsCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarListDtoGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMicrosoftCalendars(command, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getMicrosoftCalendars']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetPowerBIDashboardsRequest} [getPowerBIDashboardsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPowerBIDashboards(getPowerBIDashboardsRequest?: GetPowerBIDashboardsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmbedObjectDtoIEnumerableGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPowerBIDashboards(getPowerBIDashboardsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getPowerBIDashboards']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetPowerBIReportsRequest} [getPowerBIReportsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPowerBIReports(getPowerBIReportsRequest?: GetPowerBIReportsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmbedObjectDtoIEnumerableGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPowerBIReports(getPowerBIReportsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getPowerBIReports']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetPowerBITilesRequest} [getPowerBITilesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPowerBITiles(getPowerBITilesRequest?: GetPowerBITilesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmbedObjectDtoIEnumerableGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPowerBITiles(getPowerBITilesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getPowerBITiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetPowerBIWorkspacesRequest} [getPowerBIWorkspacesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPowerBIWorkspaces(getPowerBIWorkspacesRequest?: GetPowerBIWorkspacesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceDtoIEnumerableGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPowerBIWorkspaces(getPowerBIWorkspacesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getPowerBIWorkspaces']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [externalCustomerId] 
         * @param {Array<number>} [providers] 
         * @param {Array<number>} [searches] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSocialSearchAuthorizations(accountId?: number, externalCustomerId?: number, providers?: Array<number>, searches?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SocialAuthorizationListGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSocialSearchAuthorizations(accountId, externalCustomerId, providers, searches, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getSocialSearchAuthorizations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSportsLeagues(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSportsLeaguesResponseGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSportsLeagues(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getSportsLeagues']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetSportsTeamsRequest} [getSportsTeamsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSportsTeams(getSportsTeamsRequest?: GetSportsTeamsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSportsTeamsResponseGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSportsTeams(getSportsTeamsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getSportsTeams']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetStockSymbolsRequest} [getStockSymbolsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStockSymbols(getStockSymbolsRequest?: GetStockSymbolsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockSymbolArrayGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStockSymbols(getStockSymbolsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getStockSymbols']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RemoveFeedRequest} [removeFeedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFeed(removeFeedRequest?: RemoveFeedRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmptyResponseGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeFeed(removeFeedRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.removeFeed']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateFeedNameCommand} [updateFeedNameCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renameFeed(updateFeedNameCommand?: UpdateFeedNameCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmptyResponseGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renameFeed(updateFeedNameCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.renameFeed']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(resetPasswordRequest?: ResetPasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(resetPasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.resetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendVerifyCode(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendVerifyCodeCommandGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendVerifyCode(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.sendVerifyCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async simulateDownload(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.simulateDownload(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.simulateDownload']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscribeAccountNotifications(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationPayloadGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscribeAccountNotifications(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.subscribeAccountNotifications']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscribeToTestNotifications(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationPayloadGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscribeToTestNotifications(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.subscribeToTestNotifications']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscribeUserNotifications(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationPayloadGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscribeUserNotifications(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.subscribeUserNotifications']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [isError] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testSignageLivePublish(isError?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testSignageLivePublish(isError, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.testSignageLivePublish']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UnarchiveCustomerRequest} [unarchiveCustomerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unarchiveCustomer(unarchiveCustomerRequest?: UnarchiveCustomerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnarchiveCustomerResponseGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unarchiveCustomer(unarchiveCustomerRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.unarchiveCustomer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateConnectionAuthCommand} [command] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConnectionAuth(command?: UpdateConnectionAuthCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmptyResponseGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnectionAuth(command, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateConnectionAuth']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateCustomerRequest} [updateCustomerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomer(updateCustomerRequest?: UpdateCustomerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateCustomerResponseGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomer(updateCustomerRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateCustomer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateFeedRequest} [updateFeedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFeed(updateFeedRequest?: UpdateFeedRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateFeedResponseGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFeed(updateFeedRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateFeed']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateUserFromStoreCommand} [updateUserFromStoreCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(updateUserFromStoreCommand?: UpdateUserFromStoreCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountContactForStoreDtoGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(updateUserFromStoreCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {File} file The logo file to upload
         * @param {string} [originalFileName] Original name of the file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadLogo(file: File, originalFileName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFeedLogoResponseGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadLogo(file, originalFileName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.uploadLogo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] Optional Bearer token
         * @param {GetValidLocationQuery} [getValidLocationQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateLocation(authorization?: string, getValidLocationQuery?: GetValidLocationQuery, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateLocationResponseGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateLocation(authorization, getValidLocationQuery, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.validateLocation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VerifyCodeCommand} [verifyCodeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyCode(verifyCodeCommand?: VerifyCodeCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyCodeCommandGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyCode(verifyCodeCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.verifyCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {ArchiveCustomerRequest} [archiveCustomerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveCustomer(archiveCustomerRequest?: ArchiveCustomerRequest, options?: RawAxiosRequestConfig): AxiosPromise<ArchiveCustomerResponseGenericResponse> {
            return localVarFp.archiveCustomer(archiveCustomerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ArchiveUserCommand} [archiveUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveUser(archiveUserCommand?: ArchiveUserCommand, options?: RawAxiosRequestConfig): AxiosPromise<ArchiveUserCommandGenericResponse> {
            return localVarFp.archiveUser(archiveUserCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CloneFeedCommand} [cloneFeedCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneFeed(cloneFeedCommand?: CloneFeedCommand, options?: RawAxiosRequestConfig): AxiosPromise<CloneFeedCommandResponseGenericResponse> {
            return localVarFp.cloneFeed(cloneFeedCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAccountFromAccountCommand} [createAccountFromAccountCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(createAccountFromAccountCommand?: CreateAccountFromAccountCommand, options?: RawAxiosRequestConfig): AxiosPromise<CreateAccountFromAccountResultGenericResponse> {
            return localVarFp.createAccount(createAccountFromAccountCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateChildAccountFromStoreCommand} [createChildAccountFromStoreCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer(createChildAccountFromStoreCommand?: CreateChildAccountFromStoreCommand, options?: RawAxiosRequestConfig): AxiosPromise<CreateChildAccountResponseGenericResponse> {
            return localVarFp.createCustomer(createChildAccountFromStoreCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateFeedRequest} [createFeedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeed(createFeedRequest?: CreateFeedRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateFeedResponseGenericResponse> {
            return localVarFp.createFeed(createFeedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateSocialSearchCommand} [createSocialSearchCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSocialSearch(createSocialSearchCommand?: CreateSocialSearchCommand, options?: RawAxiosRequestConfig): AxiosPromise<SocialAuthorizationGenericResponse> {
            return localVarFp.createSocialSearch(createSocialSearchCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateUserFromStoreCommand} [createUserFromStoreCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(createUserFromStoreCommand?: CreateUserFromStoreCommand, options?: RawAxiosRequestConfig): AxiosPromise<AccountContactForStoreDtoGenericResponse> {
            return localVarFp.createUser(createUserFromStoreCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateUserMagicInviteLinkCommand} [createUserMagicInviteLinkCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserMagicInviteLink(createUserMagicInviteLinkCommand?: CreateUserMagicInviteLinkCommand, options?: RawAxiosRequestConfig): AxiosPromise<CreateUserMagicLinkInviteResultGenericResponse> {
            return localVarFp.createUserMagicInviteLink(createUserMagicInviteLinkCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgotPasswordRequest} [forgotPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(forgotPasswordRequest?: ForgotPasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forgotPassword(forgotPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetAccountRequest} [getAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(getAccountRequest?: GetAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetAccountResponseGenericResponse> {
            return localVarFp.getAccount(getAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetAccountContactsForStoreQuery} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountContact(query?: GetAccountContactsForStoreQuery, options?: RawAxiosRequestConfig): AxiosPromise<AccountContactForStoreDtoGenericResponse> {
            return localVarFp.getAccountContact(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetAccountContactsForStoreQuery} [getAccountContactsForStoreQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountContacts(getAccountContactsForStoreQuery?: GetAccountContactsForStoreQuery, options?: RawAxiosRequestConfig): AxiosPromise<AccountContactForStoreDtoArrayGenericResponse> {
            return localVarFp.getAccountContacts(getAccountContactsForStoreQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetAirportAutocompleteRequest} [getAirportAutocompleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAirportAutocomplete(getAirportAutocompleteRequest?: GetAirportAutocompleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<AirportInfoArrayGenericResponse> {
            return localVarFp.getAirportAutocomplete(getAirportAutocompleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {string} [providerSlug] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthedUsersForProvider(accountId?: number, providerSlug?: string, options?: RawAxiosRequestConfig): AxiosPromise<AuthorizationIEnumerableGenericResponse> {
            return localVarFp.getAuthedUsersForProvider(accountId, providerSlug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [providerId] 
         * @param {number} [accountId] 
         * @param {string} [clientUrl] 
         * @param {string} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizationModel(providerId?: number, accountId?: number, clientUrl?: string, version?: string, options?: RawAxiosRequestConfig): AxiosPromise<ClientAuthModelDtoGenericResponse> {
            return localVarFp.getAuthorizationModel(providerId, accountId, clientUrl, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} connectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionInfo(connectionId: string, options?: RawAxiosRequestConfig): AxiosPromise<ConnectionContextGenericResponse> {
            return localVarFp.getConnectionInfo(connectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionMetrics(options?: RawAxiosRequestConfig): AxiosPromise<ConnectionMetricsGenericResponse> {
            return localVarFp.getConnectionMetrics(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {Array<string>} [providerSlugs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionProvidersBySlug(accountId?: number, providerSlugs?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<ConnectionProviderArrayGenericResponse> {
            return localVarFp.getConnectionProvidersBySlug(accountId, providerSlugs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [authorization] Optional Bearer token
         * @param {GetContentMenuQuery} [getContentMenuQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentMenu(authorization?: string, getContentMenuQuery?: GetContentMenuQuery, options?: RawAxiosRequestConfig): AxiosPromise<PackageInfoArrayGenericResponse> {
            return localVarFp.getContentMenu(authorization, getContentMenuQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetCustomersRequest} [getCustomersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomers(getCustomersRequest?: GetCustomersRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetCustomersResponseGenericResponse> {
            return localVarFp.getCustomers(getCustomersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetFeedConfigRequest} [getFeedConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedConfig(getFeedConfigRequest?: GetFeedConfigRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetFeedConfigResponseGenericResponse> {
            return localVarFp.getFeedConfig(getFeedConfigRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetFeedDataConnectionsQuery} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedConnections(query?: GetFeedDataConnectionsQuery, options?: RawAxiosRequestConfig): AxiosPromise<GetFeedConnectionsResponseGenericResponse> {
            return localVarFp.getFeedConnections(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [authorization] Optional Bearer token
         * @param {GetFeedCreationRequest} [getFeedCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedCreation(authorization?: string, getFeedCreationRequest?: GetFeedCreationRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetFeedCreationResponseGenericResponse> {
            return localVarFp.getFeedCreation(authorization, getFeedCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetFeedsQuery} [getFeedsQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeeds(getFeedsQuery?: GetFeedsQuery, options?: RawAxiosRequestConfig): AxiosPromise<FeedInfoArrayGenericResponse> {
            return localVarFp.getFeeds(getFeedsQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetCalendarsCommand} [command] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoogleCalendars(command?: GetCalendarsCommand, options?: RawAxiosRequestConfig): AxiosPromise<CalendarListDtoGenericResponse> {
            return localVarFp.getGoogleCalendars(command, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalFavoritesCategories(options?: RawAxiosRequestConfig): AxiosPromise<CategoryItemArrayGenericResponse> {
            return localVarFp.getLocalFavoritesCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetMicrosoftCalendarsCommand} [command] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMicrosoftCalendars(command?: GetMicrosoftCalendarsCommand, options?: RawAxiosRequestConfig): AxiosPromise<CalendarListDtoGenericResponse> {
            return localVarFp.getMicrosoftCalendars(command, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetPowerBIDashboardsRequest} [getPowerBIDashboardsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowerBIDashboards(getPowerBIDashboardsRequest?: GetPowerBIDashboardsRequest, options?: RawAxiosRequestConfig): AxiosPromise<EmbedObjectDtoIEnumerableGenericResponse> {
            return localVarFp.getPowerBIDashboards(getPowerBIDashboardsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetPowerBIReportsRequest} [getPowerBIReportsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowerBIReports(getPowerBIReportsRequest?: GetPowerBIReportsRequest, options?: RawAxiosRequestConfig): AxiosPromise<EmbedObjectDtoIEnumerableGenericResponse> {
            return localVarFp.getPowerBIReports(getPowerBIReportsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetPowerBITilesRequest} [getPowerBITilesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowerBITiles(getPowerBITilesRequest?: GetPowerBITilesRequest, options?: RawAxiosRequestConfig): AxiosPromise<EmbedObjectDtoIEnumerableGenericResponse> {
            return localVarFp.getPowerBITiles(getPowerBITilesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetPowerBIWorkspacesRequest} [getPowerBIWorkspacesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPowerBIWorkspaces(getPowerBIWorkspacesRequest?: GetPowerBIWorkspacesRequest, options?: RawAxiosRequestConfig): AxiosPromise<WorkspaceDtoIEnumerableGenericResponse> {
            return localVarFp.getPowerBIWorkspaces(getPowerBIWorkspacesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [externalCustomerId] 
         * @param {Array<number>} [providers] 
         * @param {Array<number>} [searches] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSocialSearchAuthorizations(accountId?: number, externalCustomerId?: number, providers?: Array<number>, searches?: Array<number>, options?: RawAxiosRequestConfig): AxiosPromise<SocialAuthorizationListGenericResponse> {
            return localVarFp.getSocialSearchAuthorizations(accountId, externalCustomerId, providers, searches, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSportsLeagues(options?: RawAxiosRequestConfig): AxiosPromise<GetSportsLeaguesResponseGenericResponse> {
            return localVarFp.getSportsLeagues(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetSportsTeamsRequest} [getSportsTeamsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSportsTeams(getSportsTeamsRequest?: GetSportsTeamsRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetSportsTeamsResponseGenericResponse> {
            return localVarFp.getSportsTeams(getSportsTeamsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetStockSymbolsRequest} [getStockSymbolsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockSymbols(getStockSymbolsRequest?: GetStockSymbolsRequest, options?: RawAxiosRequestConfig): AxiosPromise<StockSymbolArrayGenericResponse> {
            return localVarFp.getStockSymbols(getStockSymbolsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RemoveFeedRequest} [removeFeedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFeed(removeFeedRequest?: RemoveFeedRequest, options?: RawAxiosRequestConfig): AxiosPromise<EmptyResponseGenericResponse> {
            return localVarFp.removeFeed(removeFeedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateFeedNameCommand} [updateFeedNameCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameFeed(updateFeedNameCommand?: UpdateFeedNameCommand, options?: RawAxiosRequestConfig): AxiosPromise<EmptyResponseGenericResponse> {
            return localVarFp.renameFeed(updateFeedNameCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPasswordRequest?: ResetPasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.resetPassword(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendVerifyCode(options?: RawAxiosRequestConfig): AxiosPromise<SendVerifyCodeCommandGenericResponse> {
            return localVarFp.sendVerifyCode(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simulateDownload(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.simulateDownload(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeAccountNotifications(options?: RawAxiosRequestConfig): AxiosPromise<NotificationPayloadGenericResponse> {
            return localVarFp.subscribeAccountNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeToTestNotifications(options?: RawAxiosRequestConfig): AxiosPromise<NotificationPayloadGenericResponse> {
            return localVarFp.subscribeToTestNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeUserNotifications(options?: RawAxiosRequestConfig): AxiosPromise<NotificationPayloadGenericResponse> {
            return localVarFp.subscribeUserNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isError] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testSignageLivePublish(isError?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.testSignageLivePublish(isError, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UnarchiveCustomerRequest} [unarchiveCustomerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveCustomer(unarchiveCustomerRequest?: UnarchiveCustomerRequest, options?: RawAxiosRequestConfig): AxiosPromise<UnarchiveCustomerResponseGenericResponse> {
            return localVarFp.unarchiveCustomer(unarchiveCustomerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateConnectionAuthCommand} [command] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionAuth(command?: UpdateConnectionAuthCommand, options?: RawAxiosRequestConfig): AxiosPromise<EmptyResponseGenericResponse> {
            return localVarFp.updateConnectionAuth(command, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCustomerRequest} [updateCustomerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomer(updateCustomerRequest?: UpdateCustomerRequest, options?: RawAxiosRequestConfig): AxiosPromise<UpdateCustomerResponseGenericResponse> {
            return localVarFp.updateCustomer(updateCustomerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateFeedRequest} [updateFeedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeed(updateFeedRequest?: UpdateFeedRequest, options?: RawAxiosRequestConfig): AxiosPromise<UpdateFeedResponseGenericResponse> {
            return localVarFp.updateFeed(updateFeedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserFromStoreCommand} [updateUserFromStoreCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(updateUserFromStoreCommand?: UpdateUserFromStoreCommand, options?: RawAxiosRequestConfig): AxiosPromise<AccountContactForStoreDtoGenericResponse> {
            return localVarFp.updateUser(updateUserFromStoreCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file The logo file to upload
         * @param {string} [originalFileName] Original name of the file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLogo(file: File, originalFileName?: string, options?: RawAxiosRequestConfig): AxiosPromise<UploadFeedLogoResponseGenericResponse> {
            return localVarFp.uploadLogo(file, originalFileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [authorization] Optional Bearer token
         * @param {GetValidLocationQuery} [getValidLocationQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateLocation(authorization?: string, getValidLocationQuery?: GetValidLocationQuery, options?: RawAxiosRequestConfig): AxiosPromise<ValidateLocationResponseGenericResponse> {
            return localVarFp.validateLocation(authorization, getValidLocationQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyCodeCommand} [verifyCodeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyCode(verifyCodeCommand?: VerifyCodeCommand, options?: RawAxiosRequestConfig): AxiosPromise<VerifyCodeCommandGenericResponse> {
            return localVarFp.verifyCode(verifyCodeCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {ArchiveCustomerRequest} [archiveCustomerRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public archiveCustomer(archiveCustomerRequest?: ArchiveCustomerRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).archiveCustomer(archiveCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ArchiveUserCommand} [archiveUserCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public archiveUser(archiveUserCommand?: ArchiveUserCommand, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).archiveUser(archiveUserCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CloneFeedCommand} [cloneFeedCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cloneFeed(cloneFeedCommand?: CloneFeedCommand, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).cloneFeed(cloneFeedCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAccountFromAccountCommand} [createAccountFromAccountCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createAccount(createAccountFromAccountCommand?: CreateAccountFromAccountCommand, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createAccount(createAccountFromAccountCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateChildAccountFromStoreCommand} [createChildAccountFromStoreCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createCustomer(createChildAccountFromStoreCommand?: CreateChildAccountFromStoreCommand, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createCustomer(createChildAccountFromStoreCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateFeedRequest} [createFeedRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createFeed(createFeedRequest?: CreateFeedRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createFeed(createFeedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateSocialSearchCommand} [createSocialSearchCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createSocialSearch(createSocialSearchCommand?: CreateSocialSearchCommand, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createSocialSearch(createSocialSearchCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateUserFromStoreCommand} [createUserFromStoreCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createUser(createUserFromStoreCommand?: CreateUserFromStoreCommand, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createUser(createUserFromStoreCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateUserMagicInviteLinkCommand} [createUserMagicInviteLinkCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createUserMagicInviteLink(createUserMagicInviteLinkCommand?: CreateUserMagicInviteLinkCommand, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createUserMagicInviteLink(createUserMagicInviteLinkCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForgotPasswordRequest} [forgotPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public forgotPassword(forgotPasswordRequest?: ForgotPasswordRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).forgotPassword(forgotPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetAccountRequest} [getAccountRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAccount(getAccountRequest?: GetAccountRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAccount(getAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetAccountContactsForStoreQuery} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAccountContact(query?: GetAccountContactsForStoreQuery, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAccountContact(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetAccountContactsForStoreQuery} [getAccountContactsForStoreQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAccountContacts(getAccountContactsForStoreQuery?: GetAccountContactsForStoreQuery, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAccountContacts(getAccountContactsForStoreQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetAirportAutocompleteRequest} [getAirportAutocompleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAirportAutocomplete(getAirportAutocompleteRequest?: GetAirportAutocompleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAirportAutocomplete(getAirportAutocompleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {string} [providerSlug] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAuthedUsersForProvider(accountId?: number, providerSlug?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAuthedUsersForProvider(accountId, providerSlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [providerId] 
     * @param {number} [accountId] 
     * @param {string} [clientUrl] 
     * @param {string} [version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAuthorizationModel(providerId?: number, accountId?: number, clientUrl?: string, version?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAuthorizationModel(providerId, accountId, clientUrl, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} connectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getConnectionInfo(connectionId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getConnectionInfo(connectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getConnectionMetrics(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getConnectionMetrics(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {Array<string>} [providerSlugs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getConnectionProvidersBySlug(accountId?: number, providerSlugs?: Array<string>, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getConnectionProvidersBySlug(accountId, providerSlugs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [authorization] Optional Bearer token
     * @param {GetContentMenuQuery} [getContentMenuQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getContentMenu(authorization?: string, getContentMenuQuery?: GetContentMenuQuery, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getContentMenu(authorization, getContentMenuQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetCustomersRequest} [getCustomersRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCustomers(getCustomersRequest?: GetCustomersRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCustomers(getCustomersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetFeedConfigRequest} [getFeedConfigRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getFeedConfig(getFeedConfigRequest?: GetFeedConfigRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getFeedConfig(getFeedConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetFeedDataConnectionsQuery} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getFeedConnections(query?: GetFeedDataConnectionsQuery, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getFeedConnections(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [authorization] Optional Bearer token
     * @param {GetFeedCreationRequest} [getFeedCreationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getFeedCreation(authorization?: string, getFeedCreationRequest?: GetFeedCreationRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getFeedCreation(authorization, getFeedCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetFeedsQuery} [getFeedsQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getFeeds(getFeedsQuery?: GetFeedsQuery, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getFeeds(getFeedsQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetCalendarsCommand} [command] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGoogleCalendars(command?: GetCalendarsCommand, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getGoogleCalendars(command, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getLocalFavoritesCategories(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getLocalFavoritesCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetMicrosoftCalendarsCommand} [command] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMicrosoftCalendars(command?: GetMicrosoftCalendarsCommand, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMicrosoftCalendars(command, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetPowerBIDashboardsRequest} [getPowerBIDashboardsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPowerBIDashboards(getPowerBIDashboardsRequest?: GetPowerBIDashboardsRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPowerBIDashboards(getPowerBIDashboardsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetPowerBIReportsRequest} [getPowerBIReportsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPowerBIReports(getPowerBIReportsRequest?: GetPowerBIReportsRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPowerBIReports(getPowerBIReportsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetPowerBITilesRequest} [getPowerBITilesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPowerBITiles(getPowerBITilesRequest?: GetPowerBITilesRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPowerBITiles(getPowerBITilesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetPowerBIWorkspacesRequest} [getPowerBIWorkspacesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPowerBIWorkspaces(getPowerBIWorkspacesRequest?: GetPowerBIWorkspacesRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPowerBIWorkspaces(getPowerBIWorkspacesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {number} [externalCustomerId] 
     * @param {Array<number>} [providers] 
     * @param {Array<number>} [searches] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSocialSearchAuthorizations(accountId?: number, externalCustomerId?: number, providers?: Array<number>, searches?: Array<number>, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSocialSearchAuthorizations(accountId, externalCustomerId, providers, searches, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSportsLeagues(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSportsLeagues(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetSportsTeamsRequest} [getSportsTeamsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSportsTeams(getSportsTeamsRequest?: GetSportsTeamsRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSportsTeams(getSportsTeamsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetStockSymbolsRequest} [getStockSymbolsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStockSymbols(getStockSymbolsRequest?: GetStockSymbolsRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getStockSymbols(getStockSymbolsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RemoveFeedRequest} [removeFeedRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public removeFeed(removeFeedRequest?: RemoveFeedRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).removeFeed(removeFeedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateFeedNameCommand} [updateFeedNameCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public renameFeed(updateFeedNameCommand?: UpdateFeedNameCommand, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).renameFeed(updateFeedNameCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResetPasswordRequest} [resetPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public resetPassword(resetPasswordRequest?: ResetPasswordRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).resetPassword(resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sendVerifyCode(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).sendVerifyCode(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public simulateDownload(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).simulateDownload(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public subscribeAccountNotifications(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).subscribeAccountNotifications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public subscribeToTestNotifications(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).subscribeToTestNotifications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public subscribeUserNotifications(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).subscribeUserNotifications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [isError] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public testSignageLivePublish(isError?: boolean, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).testSignageLivePublish(isError, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnarchiveCustomerRequest} [unarchiveCustomerRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public unarchiveCustomer(unarchiveCustomerRequest?: UnarchiveCustomerRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).unarchiveCustomer(unarchiveCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateConnectionAuthCommand} [command] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateConnectionAuth(command?: UpdateConnectionAuthCommand, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateConnectionAuth(command, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateCustomerRequest} [updateCustomerRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCustomer(updateCustomerRequest?: UpdateCustomerRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateCustomer(updateCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateFeedRequest} [updateFeedRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateFeed(updateFeedRequest?: UpdateFeedRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateFeed(updateFeedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserFromStoreCommand} [updateUserFromStoreCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateUser(updateUserFromStoreCommand?: UpdateUserFromStoreCommand, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateUser(updateUserFromStoreCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file The logo file to upload
     * @param {string} [originalFileName] Original name of the file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public uploadLogo(file: File, originalFileName?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).uploadLogo(file, originalFileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [authorization] Optional Bearer token
     * @param {GetValidLocationQuery} [getValidLocationQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public validateLocation(authorization?: string, getValidLocationQuery?: GetValidLocationQuery, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).validateLocation(authorization, getValidLocationQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyCodeCommand} [verifyCodeCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public verifyCode(verifyCodeCommand?: VerifyCodeCommand, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).verifyCode(verifyCodeCommand, options).then((request) => request(this.axios, this.basePath));
    }
}




import { ref } from "vue";

const isVisible = ref(false);
const text = ref<string>();

export function useSpinner() {
  const showSpinner = (show: boolean, message?: string) => {
    isVisible.value = show;
    text.value = show ? message : undefined;
  };

  return {
    isVisible,
    text,
    showSpinner
  };
}

import { ref, computed, watch, watchEffect } from "vue";
import { auth } from "./authService";
import { user } from "./user";
import { updateFeedPublishState } from "./state/feeds";
import appSettings from "./appSettings";

/**
 * Listens for publish updates for users that have
 * external feed integrations. (e.g. Signagelive)
 */
export function listenForFeedPublishUpdates() {
  const token = ref<string | null>(null);
  let ws: WebSocket | null = null;

  watchEffect(async () => {
    if (user.value?.externalAccount?.id) {
      token.value = await auth.getAccessToken();
    }
  });

  function getUrl(token: string | null) {
    const baseUrl = new URL(appSettings.backEnd.baseUrl);
    const baseWsUrl = `wss://${baseUrl.host}/acct/v2/SubscribeUserNotifications`;
    return token ? `${baseWsUrl}?token=${token}` : "";
  }

  function processMessage(event: MessageEvent) {
    const parsed = JSON.parse(event.data);
    if (parsed.data.type && parsed.data.entityId) {
      const { type, entityId, entityType } = parsed.data;
      const { timestamp } = parsed.meta;

      if (entityType === "Feed") {
        if (type === "progress") {
          updateFeedPublishState(entityId, "Pending", timestamp);
        }

        if (type === "success") {
          updateFeedPublishState(entityId, "Published", timestamp);
        }
      }
    }
  }

  const url = computed(() => getUrl(token.value));

  // Watch for token changes and reconnect if needed
  watch(
    token,
    (newToken) => {
      // Close existing connection if there is one
      if (ws) {
        ws.close();
        ws = null;
      }

      if (newToken) {
        console.log("New token available, opening connection");
        ws = new WebSocket(url.value);
        ws.onopen = () => {
          console.log("Connection opened");

          const intervalId = window.setInterval(() => {
            if (ws && ws.readyState === WebSocket.OPEN) {
              ws.send(JSON.stringify({ type: "ping" }));
            } else {
              clearInterval(intervalId);
            }
          }, 45 * 1000);
        };
        ws.onmessage = (event) => {
          console.log("Message received", event.data);
          processMessage(event);
        };
        ws.onclose = () => {
          console.log("Connection closed");
          ws = null;
        };
      }
    },
    { immediate: true },
  );
}

export const appSettings = {
  backEnd: {
    baseUrl: import.meta.env.VITE_APP_BACKEND_BASE_URL,
    baseUrlAndPath: `${import.meta.env.VITE_APP_BACKEND_BASE_URL}/${
      import.meta.env.VITE_APP_BACKEND_BASE_PATH
    }`,
  },
  openId: {
    baseUrl: import.meta.env.VITE_APP_OPENID_BASE_URL,
  },
  environment: {
    isProduction: import.meta.env.PROD,
  },
  imageHost: import.meta.env.VITE_APP_IMAGE_HOST,
};

export default appSettings;

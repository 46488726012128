<script setup lang="ts">
import { computed } from "vue";
import { user } from "@/user";
import { isDefined } from "@vueuse/core";
import { useRoute } from "vue-router";
import { ChevronLeft } from "lucide-vue-next";

const route = useRoute();

const isExternalAccount = computed(() => {
  return (
    isDefined(user.value?.externalAccount) &&
    isDefined(user.value?.externalAccount.id)
  );
});

const showBackToContentMenu = computed(() => {
  return route.path.startsWith("/p/");
});

const showChoosePlan = computed(() => {
  return (
    isExternalAccount.value === false && showBackToContentMenu.value === false
  );
});

const showSubscribe = computed(() => {
  return (
    isExternalAccount.value === false && showBackToContentMenu.value === false
  );
});
</script>

<template>
  <RouterLink v-if="showSubscribe" to="/subscribe" class="hover:underline"
    >Subscribe</RouterLink
  >
  <RouterLink v-if="showChoosePlan" to="/choose-plan" class="hover:underline"
    >Choose a Plan</RouterLink
  >

  <RouterLink
    to="/"
    class="flex items-center space-x-1 hover:underline"
    v-if="showBackToContentMenu"
  >
    <ChevronLeft class="size-4" />
    <span>Back to Content Menu</span>
  </RouterLink>
</template>
